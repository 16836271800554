// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PricesComponent from './PricesComponent';
import TdhStatsComponent from './TdhStatsComponent';
import TdhTableComponent from './TdhTableComponent';
import PricesHistoryComponent from './PricesHistoryComponent';
import MyPortfolioComponent from './MyPortfolioComponent';
import SalesSummaryComponent from './SalesSummaryComponent';
import SalesBotAllTimeComponent from './SalesBotAllTimeComponent';
import SalesAnalyticsComponent from './SalesAnalyticsComponent';
import NotificationsComponent from './NotificationsComponent';
import FooterComponent from './FooterComponent';
import TokenOwnersComponent from './TokenOwnersComponent';
import HodlerAggregatedComponent from './HodlerAggregatedComponent';
import MyPortfolioBidsComponent from './MyPortfolioBidsComponent';
import HodlerSupplyEnhancedComponent from './HodlerSupplyEnhancedComponent';
import HodlerSupplyComponent from './HodlerSupplyComponent';
import HodlersComponent from './HodlersComponent';
import HodlersIndividualsComponent from './HodlersIndividualsComponent';
import ContactForm from './ContactForm';
import TokenComponent from './TokenComponent';
import InsightsComponent from './InsightsComponent';
import Navbar from './Navbar';
import AboutComponent from './AboutComponent';
import PebblesMainComponent from './PebblesMainComponent';
import PebblesSalesComponent from './PebblesSalesComponent';
import ArtistComponent from './ArtistComponent';
import HomeComponent from './HomeComponent';
import ServicesComponent from './ServicesComponent';
import NFTCollectionsComponent from './NFTCollectionsComponent';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content-wrapper">
          <Navbar />
          <main>
            <Routes>
              <Route path="/" element={<HomeComponent />} />
              <Route path="/prices" element={<PricesComponent />} />
              <Route path="/insights" element={<InsightsComponent />} />
              <Route path="/services" element={<ServicesComponent />} />
              <Route path="/nft-collections" element={<NFTCollectionsComponent />} />

              <Route path="/tdh-stats" element={<TdhStatsComponent />} />
              <Route path="/tdh-table" element={<TdhTableComponent />} />
              <Route path="/prices-history" element={<PricesHistoryComponent />} />
              <Route path="/sales-summary" element={<SalesSummaryComponent />} />
              <Route path="/sales-analytics" element={<SalesAnalyticsComponent />} />
              <Route path="/sales-all-time" element={<SalesBotAllTimeComponent />} />

              <Route path="/portfolio" element={<MyPortfolioComponent />} />
              <Route path="/my-portfolio-bids" element={<MyPortfolioBidsComponent />} />

              <Route path="/notifications" element={<NotificationsComponent />} />
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/about" element={<AboutComponent />} />
              <Route path="/about/artists" element={<ArtistComponent />} />

              <Route path="/token/:tokenid" element={<TokenComponent />} />
              <Route path="/token/:tokenid/owners" element={<TokenOwnersComponent />} />

              <Route path="/hodlers" element={<HodlersComponent />} />
              <Route path="/hodlers/:profile" element={<HodlersIndividualsComponent />} />
              <Route path="/hodlers/aggregated" element={<HodlerAggregatedComponent />} />
              <Route path="/hodlers/supply" element={<HodlerSupplyComponent />} />
              <Route path="/hodlers/supply-enhanced" element={<HodlerSupplyEnhancedComponent />} />

              <Route path="/nextgen/pebbles/prices" element={<PebblesMainComponent />} />
              <Route path="/nextgen/pebbles/sales" element={<PebblesSalesComponent />} />
            </Routes>
          </main>
        </div>
        <FooterComponent />
      </div>
    </Router>
  );
}

export default App;
