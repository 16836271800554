// PricesHistoryComponent.js

import React, { useState, useEffect, useCallback } from 'react';
import { 
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer 
} from 'recharts';

// Define the base URL for your API
const API_BASE_URL = 'https://api.6529stats.xyz/api';

const PricesHistoryComponent = () => {
    // State variables to manage fetched data and UI states
    const [pricesHistory, setPricesHistory] = useState([]);
    const [marketCapHistory, setMarketCapHistory] = useState([]);
    const [individualSZNBidHistory, setIndividualSZNBidHistory] = useState([]);
    const [individualSZNAskHistory, setIndividualSZNAskHistory] = useState([]);
    const [lastUpdated, setLastUpdated] = useState('');
    const [allSZNs, setAllSZNs] = useState(new Set()); // Track all SZNs present in the data
    const [fetchError, setFetchError] = useState(''); // State for handling fetch errors

    /**
     * Processes the raw data fetched from the API.
     * - Groups data by date.
     * - Selects the entry nearest to midday for each date.
     * - Aggregates total ask, bid, and market cap values.
     * - Extracts individual SZN bid and ask totals.
     * - Ensures that each data point includes all SZNs, setting missing values to 0.
     */
    const processDataSet = useCallback((dataSet) => {
        // Group the data by date
        const groupedByDate = dataSet.reduce((acc, item) => {
            const date = item.Timestamp.split('T')[0];
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});

        let sznSet = new Set(); // Temporary set to collect all SZNs

        // First pass: Collect all SZNs
        Object.values(groupedByDate).forEach(entries => {
            entries.forEach(entry => {
                if (entry.SZN_ID) {
                    sznSet.add(entry.SZN_ID);
                }
            });
        });

        setAllSZNs(sznSet); // Update the state with all SZNs

        // Second pass: Process each date's data
        const processedData = Object.entries(groupedByDate).map(([date, entries]) => {
            const middayTime = new Date(`${date}T12:00:00`).getTime();

            // Find the entry nearest to midday
            const nearestToMidday = entries.reduce((nearest, entry) => {
                const entryTime = new Date(entry.Timestamp).getTime();
                const currentDiff = Math.abs(entryTime - middayTime);
                const nearestDiff = Math.abs(new Date(nearest.Timestamp).getTime() - middayTime);
                return currentDiff < nearestDiff ? entry : nearest;
            });

            // Filter entries that match the nearest timestamp
            const relevantEntries = entries.filter(entry => entry.Timestamp === nearestToMidday.Timestamp);

            // Initialize aggregators
            let askTotal = 0;
            let bidTotal = 0;
            let marketCapAskTotal = 0;
            let marketCapBidTotal = 0;
            const individualSZNs = {};

            // Aggregate data
            relevantEntries.forEach(entry => {
                const sznId = entry.SZN_ID;
                askTotal += parseFloat(entry.AskTotal) || 0;
                bidTotal += parseFloat(entry.BidTotal) || 0;
                marketCapAskTotal += parseFloat(entry.MarketCapAsk) || 0;
                marketCapBidTotal += parseFloat(entry.MarketCapBid) || 0;

                // Dynamically create keys for SZNs
                individualSZNs[`SZN${sznId}BidTotal`] = parseFloat(entry.BidTotal) || 0;
                individualSZNs[`SZN${sznId}AskTotal`] = parseFloat(entry.AskTotal) || 0;
            });

            // Ensure all SZNs are present in the data point, setting missing values to 0
            sznSet.forEach(sznId => {
                if (!individualSZNs[`SZN${sznId}BidTotal`]) {
                    individualSZNs[`SZN${sznId}BidTotal`] = 0;
                }
                if (!individualSZNs[`SZN${sznId}AskTotal`]) {
                    individualSZNs[`SZN${sznId}AskTotal`] = 0;
                }
            });

            // Log the processed data point for verification
            console.log("Processed Data Point:", {
                date,
                askTotal,
                bidTotal,
                marketCapAskTotal,
                marketCapBidTotal,
                ...individualSZNs,
                timestamp: nearestToMidday.Timestamp
            });

            return {
                date,
                askTotal: parseFloat(askTotal.toFixed(4)),
                bidTotal: parseFloat(bidTotal.toFixed(4)),
                marketCapAskTotal: parseFloat(marketCapAskTotal.toFixed(2)),
                marketCapBidTotal: parseFloat(marketCapBidTotal.toFixed(2)),
                ...individualSZNs,
                timestamp: nearestToMidday.Timestamp
            };
        });

        // Sort the processed data by date in ascending order
        return processedData.sort((a, b) => new Date(a.date) - new Date(b.date));
    }, []);

    /**
     * Further processes the data for specific charts.
     * - Filters data starting from a specified start date.
     * - Omits the erroneous date from Individual SZN charts.
     */
    const processDataForCharts = useCallback((data) => {
        const startDate = '2024-09-18'; // Adjust as needed
        const excludedDate = '2024-10-02'; // Date to exclude from Individual SZN charts

        // Process all data for complete collection and market cap charts
        const processedData = processDataSet(data);

        // Filter data for market cap starting from the specified date
        const marketCapData = processedData.filter(item => item.date >= startDate);

        // Filter data to exclude the erroneous date for Individual SZN charts
        const individualSZNData = processedData.filter(item => item.date >= startDate && item.date !== excludedDate);

        return { 
            pricesData: processedData,
            marketCapData: marketCapData,
            individualSZNBidData: individualSZNData,
            individualSZNAskData: individualSZNData
        };
    }, [processDataSet]);

    /**
     * Fetches data from the API and updates state variables accordingly.
     */
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/api/szn_prices_history`);
                const data = await response.json();
                console.log("Raw API data:", data);

                // Validate the API response structure
                if (!data.data || !Array.isArray(data.data)) {
                    throw new Error("Invalid data format received from API.");
                }

                // Process the data for all charts
                const { pricesData, marketCapData, individualSZNBidData, individualSZNAskData } = processDataForCharts(data.data);
                setPricesHistory(pricesData);
                setMarketCapHistory(marketCapData);
                setIndividualSZNBidHistory(individualSZNBidData);
                setIndividualSZNAskHistory(individualSZNAskData);

                // Log the processed data for verification
                console.log("Individual SZN Bid History:", individualSZNBidData);
                console.log("Individual SZN Ask History:", individualSZNAskData);

                // Set the last updated date
                if (pricesData.length > 0) {
                    const lastDataPoint = pricesData[pricesData.length - 1];
                    setLastUpdated(lastDataPoint.date);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setFetchError('Failed to load price history. Please try again later.');
            }
        };

        fetchData();
    }, [processDataForCharts]);

    /**
     * Custom tooltip component for charts.
     */
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            console.log("Tooltip Payload:", payload); // Log the payload for debugging
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label">{`Date: ${label}`}</p>
                    <p className="intro">{`Timestamp: ${payload[0].payload.timestamp}`}</p>
                    {payload.map((pld, index) => (
                        <p key={index} className="desc">{`${pld.name}: ${pld.value}`}</p>
                    ))}
                </div>
            );
        }
        return null;
    };

    /**
     * Dynamically renders individual SZN lines for a given type (Bid/Ask).
     * Ensures that any number of SZNs are handled gracefully by using the allSZNs state.
     */
    const renderIndividualSZNLines = (type) => {
        // Define a palette of colors. Extend this array if you have more SZNs.
        const colors = [
            '#FF0000', '#0000FF', '#00FF00', '#FF00FF', 
            '#00FFFF', '#FFA500', '#800080', '#008000',
            '#FFC0CB', '#800000', '#808000', '#008080',
            '#000080', '#FFD700', '#4B0082', '#7FFF00',
            '#DC143C', '#00CED1', '#228B22', '#FF1493' // Added more colors for additional SZNs
        ];

        // Convert the allSZNs Set to an array and sort numerically
        const uniqueSZNs = Array.from(allSZNs).sort((a, b) => a - b);

        // Log the unique SZNs being rendered for debugging
        console.log(`Rendering Lines for ${type}:`, uniqueSZNs);

        // Render a Line for each SZN
        return uniqueSZNs.map((szn, index) => (
            <Line
                key={`SZN${szn}${type}Total`}
                type="monotone"
                dataKey={`SZN${szn}${type}Total`}
                name={`SZN ${szn} ${type}`}
                stroke={colors[index % colors.length]}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 8 }}
            />
        ));
    };

    /**
     * A reusable chart section component for better readability.
     */
    const ChartSection = ({ title, children }) => (
        <div style={{
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '20px',
            marginBottom: '40px',
            backgroundColor: '#f8f9fa'
        }}>
            <h2 className="sub-header" style={{ 
                textAlign: 'center', 
                marginBottom: '20px',
                backgroundColor: '#e9ecef',
                padding: '10px',
                borderRadius: '4px'
            }}>{title}</h2>
            {children}
        </div>
    );

    return (
        <div className="prices-history-container" style={{ backgroundColor: '#ffffff', padding: '20px' }}>
            {/* Main Header */}
            <h1 className="main-header" style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Prices History</h1>
            {lastUpdated && (
                <p className="last-updated" style={{ textAlign: 'center', marginBottom: '30px', color: '#666' }}>
                    Last Updated: {lastUpdated}
                </p>
            )}
            {fetchError && (
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <p style={{ color: 'red' }}>{fetchError}</p>
                    <button onClick={() => window.location.reload()} style={{ padding: '10px 20px', cursor: 'pointer' }}>
                        Retry
                    </button>
                </div>
            )}

            {/* Complete Collection Valuation Chart */}
            <ChartSection title="Complete Collection Valuation">
                <div className="chart-container" style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={pricesHistory}
                            aria-label="Complete Collection Valuation Line Chart"
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Line 
                                type="monotone" 
                                dataKey="askTotal" 
                                name="Ask Total" 
                                stroke="#FF0000" 
                                strokeWidth={3}
                                dot={false}
                                activeDot={{ r: 8 }}
                            />
                            <Line 
                                type="monotone" 
                                dataKey="bidTotal" 
                                name="Bid Total" 
                                stroke="#0000FF" 
                                strokeWidth={3}
                                dot={false}
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </ChartSection>

            {/* Market Cap Valuations Chart */}
            <ChartSection title="Market Cap Valuations">
                <div className="chart-container" style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={marketCapHistory}
                            aria-label="Market Cap Valuations Line Chart"
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="date" 
                                type="category"
                                tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
                            />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Line 
                                type="monotone" 
                                dataKey="marketCapAskTotal" 
                                name="Market Cap Ask" 
                                stroke="#FF0000" 
                                strokeWidth={3}
                                dot={false}
                                activeDot={{ r: 8 }}
                            />
                            <Line 
                                type="monotone" 
                                dataKey="marketCapBidTotal" 
                                name="Market Cap Bid" 
                                stroke="#0000FF" 
                                strokeWidth={3}
                                dot={false}
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </ChartSection>

            {/* Individual SZN Valuations (Bid Prices) Chart */}
            <ChartSection title="Individual SZN Valuations (Bid Prices)">
                <div className="chart-container" style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={individualSZNBidHistory}
                            aria-label="Individual SZN Valuations Bid Prices Line Chart"
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            {renderIndividualSZNLines('Bid')}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </ChartSection>

            {/* Individual SZN Valuations (Ask Prices) Chart */}
            <ChartSection title="Individual SZN Valuations (Ask Prices)">
                <div className="chart-container" style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={individualSZNAskHistory}
                            aria-label="Individual SZN Valuations Ask Prices Line Chart"
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            {renderIndividualSZNLines('Ask')}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </ChartSection>
        </div>
    ); // Closing the JSX return

}; // End of PricesHistoryComponent function

// Export the component at the top level
export default PricesHistoryComponent;
