// src/Navbar.js

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Removed useNavigate
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setActiveDropdown(null);
  };

  const toggleDropdown = (menu) => {
    if (activeDropdown === menu) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(menu);
    }
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        {/* Hamburger Menu Icon for Mobile */}
        <div className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        {/* Navigation Menu */}
        <ul className={`menu ${isMenuOpen ? 'active' : ''}`}>
          {/* 1. Home Link */}
          <li>
            <NavLink
              to="/"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Home
            </NavLink>
          </li>

          {/* 2. Prices Link */}
          <li>
            <NavLink
              to="/prices"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Prices
            </NavLink>
          </li>

          {/* 3. Sales Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'sales' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('sales')}>Sales</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/sales-summary"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Sales Summary
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sales-analytics"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Sales Analytics
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sales-all-time"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Sales History
                </NavLink>
              </li>
            </ul>
          </li>

          {/* 4. TDH/PPTDH Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'tdh' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('tdh')}>TDH/PPTDH</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/tdh-table"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Data Table
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/tdh-stats"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Historical Stats
                </NavLink>
              </li>
            </ul>
          </li>

          {/* 5. Hodlers Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'hodlers' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('hodlers')}>Hodlers</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/hodlers"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  6529 Hodlers
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hodlers/aggregated"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Aggregated
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hodlers/supply"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Hodlers Supply
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hodlers/supply-enhanced"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Hodlers Supply Enhanced
                </NavLink>
              </li>
            </ul>
          </li>

          {/* 6. Insights Link */}
          <li>
            <NavLink
              to="/insights"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Insights
            </NavLink>
          </li>

          {/* Add Next Gen Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'nextgen' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('nextgen')}>Next Gen</span>
            <ul className="dropdown-menu">
              <li className={`dropdown ${activeDropdown === 'pebbles' ? 'active' : ''}`}>
                <span onClick={() => toggleDropdown('pebbles')}>Pebbles</span>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink
                      to="/nextgen/pebbles/prices"
                      onClick={closeMenu}
                      className={({ isActive }) => (isActive ? 'active-link' : '')}
                    >
                      Prices
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/nextgen/pebbles/sales"
                      onClick={closeMenu}
                      className={({ isActive }) => (isActive ? 'active-link' : '')}
                    >
                      Sales
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          {/* 7. Services Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'services' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('services')}>Services</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/portfolio"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Portfolio
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/notifications"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Notifications
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Services Overview
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/nft-collections"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  NFT Collections
                </NavLink>
              </li>
            </ul>
          </li>

          {/* 8. About Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'about' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('about')}>About</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/about"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about/artists"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  6529 Memes Artists
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
