import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import './HodlersIndividualsComponent.css';
import { fetchHolderIndividualData } from './apiService';
import seizeIcon from './assets/seizeIcon.png';
import openseaIcon from './assets/openseaIcon.png';
import blurIcon from './assets/blurIcon.png';
import { ArrowUpDown } from 'lucide-react';

function HodlersIndividualsComponent() {
    const { profile } = useParams();
    const [holderData, setHolderData] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterCollection, setFilterCollection] = useState('All');
    const [sortConfig, setSortConfig] = useState({ key: 'tokenNo', direction: 'asc' });
    const [nameFilter, setNameFilter] = useState('');
    const [portfolioSortConfig, setPortfolioSortConfig] = useState({ key: 'collection', direction: 'asc' });
    const [portfolioFilterCollection, setPortfolioFilterCollection] = useState('All');
    const itemsPerPage = 25;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchHolderIndividualData(profile);
                setHolderData(data);
            } catch (error) {
                console.error('Error fetching holder data:', error);
                setError(error.message);
            }
        };

        fetchData();
    }, [profile]);

    if (error) {
        return <div className="holders-individual-error">Error: {error}</div>;
    }

    if (!holderData) {
        return <div className="holders-individual-loading">Loading...</div>;
    }

    // Filter NFTs based on collection and name
    const filteredNFTs = holderData.nftsList.filter(nft => 
        (filterCollection === 'All' || nft.collection === filterCollection) &&
        (!nameFilter || nft.name.toLowerCase().includes(nameFilter.toLowerCase()))
    );

    // Add sorting function
    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
        setCurrentPage(0);
    };

    // Add sorting logic
    const sortedAndFilteredNFTs = [...filteredNFTs].sort((a, b) => {
        const direction = sortConfig.direction === 'asc' ? 1 : -1;
        
        switch (sortConfig.key) {
            case 'collection':
                return direction * (a.collection || '').localeCompare(b.collection || '');
            case 'tokenNo':
                return direction * (parseInt(a.tokenNo) - parseInt(b.tokenNo));
            case 'quantity':
                return direction * (a.quantity - b.quantity);
            case 'supply':
                return direction * (a.supply - b.supply);
            case 'season':
                const seasonA = a.season === 'NA' ? -1 : parseInt(a.season);
                const seasonB = b.season === 'NA' ? -1 : parseInt(b.season);
                return direction * (seasonA - seasonB);
            case 'tokenFloor':
                const floorA = a.tokenFloor || 0;
                const floorB = b.tokenFloor || 0;
                return direction * (floorA - floorB);
            case 'collectionFloor':
                const cFloorA = a.collectionFloor || 0;
                const cFloorB = b.collectionFloor || 0;
                return direction * (cFloorA - cFloorB);
            case 'topBid':
                const bidA = a.topBid || 0;
                const bidB = b.topBid || 0;
                return direction * (bidA - bidB);
            case 'lastSalePrice':
                const saleA = a.lastSalePrice || 0;
                const saleB = b.lastSalePrice || 0;
                return direction * (saleA - saleB);
            case 'lastSaleDate':
                const dateA = a.lastSaleDate ? new Date(a.lastSaleDate).getTime() : 0;
                const dateB = b.lastSaleDate ? new Date(b.lastSaleDate).getTime() : 0;
                return direction * (dateA - dateB);
            default:
                return 0;
        }
    });

    // Calculate pagination
    const pageCount = Math.ceil(sortedAndFilteredNFTs.length / itemsPerPage);
    const offset = currentPage * itemsPerPage;
    const currentNFTs = sortedAndFilteredNFTs.slice(offset, offset + itemsPerPage);

    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const handleFilterChange = (value) => {
        setFilterCollection(value);
        setCurrentPage(0);
    };

    // Function to format timestamp
    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        
        // Convert Unix timestamp to milliseconds if needed
        const date = new Date(typeof timestamp === 'number' && timestamp < 10000000000 
            ? timestamp * 1000  // Convert seconds to milliseconds
            : timestamp        // Already in milliseconds
        );

        // Check if date is valid
        if (isNaN(date.getTime())) return '';

        // Use toLocaleDateString for locale-specific formatting
        return date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
    };

    // Function to check if it's a never sold case
    const isNeverSold = (price, timestamp) => {
        if (!price && !timestamp) return true;  // If both are missing, consider it never sold
        if (price === 0.06529 && timestamp >= 1707350400) return true;  // Feb 8, 2024 00:00:00 GMT
        return false;
    };

    // Filter portfolio valuations based on collection
    const filteredPortfolioValuations = holderData.portfolioValuations ? 
        holderData.portfolioValuations.filter(item => 
            portfolioFilterCollection === 'All' || item.collection === portfolioFilterCollection
        ) : [];

    // Sort filtered portfolio valuations
    const sortedPortfolioValuations = [...filteredPortfolioValuations].sort((a, b) => {
        const direction = portfolioSortConfig.direction === 'asc' ? 1 : -1;
        
        switch (portfolioSortConfig.key) {
            case 'collection':
                return direction * a.collection.localeCompare(b.collection);
            case 'bidPrice':
                return direction * (a.bidPrice - b.bidPrice);
            case 'tokenFloor':
                return direction * (a.tokenFloor - b.tokenFloor);
            case 'collectionFloor':
                return direction * (a.collectionFloor - b.collectionFloor);
            case 'lastSold':
                return direction * (a.lastSold - b.lastSold);
            default:
                return 0;
        }
    });

    // Calculate portfolio total (sum of all collections regardless of filter)
    const portfolioTotal = holderData.portfolioValuations ? 
        holderData.portfolioValuations.reduce((acc, item) => ({
            bidPrice: acc.bidPrice + item.bidPrice,
            tokenFloor: acc.tokenFloor + item.tokenFloor,
            collectionFloor: acc.collectionFloor + item.collectionFloor,
            lastSold: acc.lastSold + item.lastSold
        }), { bidPrice: 0, tokenFloor: 0, collectionFloor: 0, lastSold: 0 }) : 
        { bidPrice: 0, tokenFloor: 0, collectionFloor: 0, lastSold: 0 };

    // Add portfolio sorting function
    const handlePortfolioSort = (key) => {
        setPortfolioSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    return (
        <div className="holders-individual-main">
            <h1 className="holders-individual-title">
                <span className="holders-title-white">6529 Profile</span>{' '}
                <span className="holders-title-accent">{holderData.custom_profile}</span>{' '}
                <span className="holders-title-white">| TDH </span>
                <span className="holders-title-accent">{holderData.tdh}</span>
            </h1>

            <div className="holders-individual-section holders-section-spacing">
                <h2 className="holders-individual-section-title">NFT Ownership Summary</h2>
                <table className="holders-table">
                    <thead>
                        <tr>
                            <th>Memes Total</th>
                            <th>Memes Unique</th>
                            <th>Memes Sets</th>
                            <th>Pebbles</th>
                            <th>mfers</th>
                            <th>Rektguys</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{holderData.overall_balance_of_cards || 0}</td>
                            <td>{holderData.overall_num_unique_cards || 0}</td>
                            <td>{holderData.overall_num_full_sets || 0}</td>
                            <td>{holderData.pebblesbalance || 0}</td>
                            <td>{holderData.mfersbalance || 0}</td>
                            <td>{holderData.rektguybalance || 0}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="holders-individual-section holders-section-spacing">
                <h2 className="holders-individual-section-title">6529 Memes Summary</h2>
                <table className="holders-table">
                    <thead>
                        <tr>
                            <th>Season</th>
                            <th>Total</th>
                            <th>Unique</th>
                            <th>Sets</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(10)].map((_, index) => {
                            const season = index + 1;
                            return (
                                <tr key={season}>
                                    <td>{season}</td>
                                    <td>{holderData[`szn${season}_balance_of_cards`] || 0}</td>
                                    <td>{holderData[`szn${season}_num_unique_cards`] || 0}</td>
                                    <td>{holderData[`szn${season}_num_full_sets`] || 0}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="holders-individual-section holders-section-spacing">
                <h2 className="holders-individual-section-title">All NFTs Owned</h2>
                
                {/* Filters Form */}
                <form className="holders-filters-form">
                    <div className="holders-filters-row">
                        <div className="holders-filter-item">
                            <label>Collection</label>
                            <select
                                value={filterCollection}
                                onChange={(e) => handleFilterChange(e.target.value)}
                            >
                                <option value="All">All</option>
                                <option value="6529 Memes">6529 Memes</option>
                                <option value="Pebbles">Pebbles</option>
                                <option value="mfers">mfers</option>
                                <option value="rektguy">rektguy</option>
                            </select>
                        </div>
                        <div className="holders-filter-item">
                            <label>Name Search</label>
                            <input
                                type="text"
                                value={nameFilter}
                                onChange={(e) => setNameFilter(e.target.value)}
                                placeholder="Search by name..."
                                className="name-search-input"
                            />
                        </div>
                    </div>
                    <div className="holders-action-buttons-row">
                        <a 
                            href="https://6529stats.xyz/portfolio"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="portfolio-value-link"
                        >
                            View Portfolio Value
                        </a>
                        <span className="holders-records-count">
                            <strong>Number of Records:</strong> {filteredNFTs.length}
                        </span>
                    </div>
                </form>

                <table className="holders-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('collection')}>
                                <div className="holders-header-content">
                                    Collection
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'collection' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('tokenNo')}>
                                <div className="holders-header-content">
                                    Token No
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'tokenNo' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th>Name</th>
                            <th onClick={() => handleSort('quantity')}>
                                <div className="holders-header-content">
                                    Quantity
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'quantity' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th>Artist</th>
                            <th onClick={() => handleSort('supply')}>
                                <div className="holders-header-content">
                                    Supply
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'supply' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('season')}>
                                <div className="holders-header-content">
                                    Season
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'season' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('tokenFloor')}>
                                <div className="holders-header-content">
                                    Token Floor
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'tokenFloor' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('collectionFloor')}>
                                <div className="holders-header-content">
                                    Collection Floor
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'collectionFloor' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('topBid')}>
                                <div className="holders-header-content">
                                    Top Bid
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'topBid' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('lastSalePrice')}>
                                <div className="holders-header-content">
                                    Last Sale
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'lastSalePrice' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('lastSaleDate')}>
                                <div className="holders-header-content">
                                    Last Sale Date
                                    <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'lastSaleDate' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th>6529</th>
                            <th>OS</th>
                            <th>Blur</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentNFTs.map((nft, index) => (
                            <tr key={index}>
                                <td>{nft.collection}</td>
                                <td>
                                    {nft.type === 'meme' ? (
                                        <a 
                                            href={`https://6529stats.xyz/token/${nft.tokenNo}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="token-link"
                                        >
                                            {nft.tokenNo}
                                        </a>
                                    ) : (
                                        <span>{nft.tokenNo}</span>
                                    )}
                                </td>
                                <td>
                                    {nft.type === 'meme' ? (
                                        <a 
                                            href={`https://6529stats.xyz/token/${nft.tokenNo}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="token-link"
                                        >
                                            {nft.name}
                                        </a>
                                    ) : (
                                        <span>{nft.name}</span>
                                    )}
                                </td>
                                <td>{nft.quantity}</td>
                                <td>
                                    {nft.type === 'meme' ? (
                                        nft.artistHandle ? (
                                            <a 
                                                href={`https://6529.io/${nft.artistHandle}/identity`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="artist-link"
                                            >
                                                {nft.artistHandle}
                                            </a>
                                        ) : ''
                                    ) : nft.type === 'pebble' ? (
                                        <a 
                                            href="https://6529.io/nextgen/collection/pebbles"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="artist-link"
                                        >
                                            Zeblocks
                                        </a>
                                    ) : nft.type === 'rektguy' ? (
                                        <a 
                                            href="https://6529.io/0x0f5d5751d4f300c3d0adbcadc05f0af4f8baf788/identity"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="artist-link"
                                        >
                                            OSF
                                        </a>
                                    ) : (
                                        <span>Sartoshi</span>
                                    )}
                                </td>
                                <td>{nft.supply}</td>
                                <td>{nft.season || 'NA'}</td>
                                <td>
                                    {nft.type === 'meme' ? (
                                        nft.tokenFloor ? nft.tokenFloor.toFixed(4) : ''
                                    ) : (
                                        <div className="floor-price">
                                            <span>
                                                {nft.tokenFloor ? nft.tokenFloor.toFixed(4) : 'Not Listed'}
                                            </span>
                                            {nft.floorSourceIcon && nft.floorSourceUrl && nft.tokenFloor && (
                                                <a 
                                                    href={nft.floorSourceUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="marketplace-link"
                                                >
                                                    <img src={nft.floorSourceIcon} alt="Source" className="marketplace-icon" />
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </td>
                                <td>{nft.collectionFloor ? nft.collectionFloor.toFixed(4) : ''}</td>
                                <td>
                                    {nft.type === 'meme' ? (
                                        nft.topBid ? nft.topBid.toFixed(4) : ''
                                    ) : (
                                        <div className="floor-price">
                                            <span>{nft.topBid ? nft.topBid.toFixed(4) : ''}</span>
                                            {nft.topBidSourceIcon && nft.topBidSourceUrl && nft.topBid && (
                                                <a 
                                                    href={nft.topBidSourceUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="marketplace-link"
                                                >
                                                    <img src={nft.topBidSourceIcon} alt="Source" className="marketplace-icon" />
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {isNeverSold(nft.lastSalePrice, nft.lastSaleDate) 
                                        ? 'Never Sold' 
                                        : (nft.lastSalePrice ? nft.lastSalePrice.toFixed(4) : '')}
                                </td>
                                <td>
                                    {isNeverSold(nft.lastSalePrice, nft.lastSaleDate)
                                        ? 'Never Sold'
                                        : formatTimestamp(nft.lastSaleDate)}
                                </td>
                                <td>
                                    {nft.type !== 'mfer' && (
                                        <a 
                                            href={nft.type === 'meme' 
                                                ? `https://6529.io/the-memes/${nft.tokenNo}?focus=the-art`
                                                : `https://6529.io/nextgen/token/${nft.tokenNo}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="marketplace-link"
                                        >
                                            <img src={seizeIcon} alt="6529" className="marketplace-icon" />
                                        </a>
                                    )}
                                </td>
                                <td>
                                    <a 
                                        href={nft.type === 'meme'
                                            ? `https://opensea.io/assets/ethereum/0x33fd426905f149f8376e227d0c9d3340aad17af1/${nft.tokenNo}`
                                            : nft.type === 'pebble'
                                                ? `https://opensea.io/assets/ethereum/0x45882f9bc325e14fbb298a1df930c43a874b83ae/${nft.tokenNo}`
                                                : nft.type === 'rektguy'
                                                    ? `https://opensea.io/assets/ethereum/0xb852c6b5892256c264cc2c888ea462189154d8d7/${nft.tokenNo}`
                                                    : `https://opensea.io/assets/ethereum/0x79fcdef22feed20eddacbb2587640e45491b757f/${nft.tokenNo}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="marketplace-link"
                                    >
                                        <img src={openseaIcon} alt="OpenSea" className="marketplace-icon" />
                                    </a>
                                </td>
                                <td>
                                    {nft.type !== 'meme' && (
                                        <a 
                                            href={nft.type === 'pebble'
                                                ? `https://blur.io/eth/asset/0x45882f9bc325e14fbb298a1df930c43a874b83ae/${nft.tokenNo}`
                                                : nft.type === 'rektguy'
                                                    ? `https://blur.io/eth/asset/0xb852c6b5892256c264cc2c888ea462189154d8d7/${nft.tokenNo}`
                                                    : `https://blur.io/eth/asset/0x79fcdef22feed20eddacbb2587640e45491b757f/${nft.tokenNo}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="marketplace-link"
                                        >
                                            <img src={blurIcon} alt="Blur" className="marketplace-icon" />
                                        </a>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {pageCount > 1 && (
                    <ReactPaginate
                        previousLabel={'← Previous'}
                        nextLabel={'Next →'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'holders-pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                    />
                )}
            </div>

            <div className="holders-individual-section holders-section-spacing">
                <h2 className="holders-individual-section-title">Portfolio Value</h2>
                <p className="portfolio-explainer">
                    6529 Memes Collection are ERC-1155 and so for a more comprehensive Portfolio view please visit our{' '}
                    <a href="https://6529stats.xyz/portfolio" target="_blank" rel="noopener noreferrer">Portfolio Page</a>
                </p>
                
                {/* Add collection filter */}
                <div className="holders-filters-form">
                    <div className="holders-filters-row">
                        <div className="holders-filter-item">
                            <label>Collection</label>
                            <select
                                value={portfolioFilterCollection}
                                onChange={(e) => setPortfolioFilterCollection(e.target.value)}
                            >
                                <option value="All">All</option>
                                <option value="6529 Memes">6529 Memes</option>
                                <option value="Pebbles">Pebbles</option>
                                <option value="mfers">mfers</option>
                                <option value="rektguy">rektguy</option>
                            </select>
                        </div>
                    </div>
                </div>

                <table className="holders-table">
                    <thead>
                        <tr>
                            <th onClick={() => handlePortfolioSort('collection')}>
                                <div className="holders-header-content">
                                    Collection
                                    <ArrowUpDown className={`holders-sort-icon ${portfolioSortConfig.key === 'collection' ? (portfolioSortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handlePortfolioSort('bidPrice')}>
                                <div className="holders-header-content">
                                    Valuation (Bid Prices)
                                    <ArrowUpDown className={`holders-sort-icon ${portfolioSortConfig.key === 'bidPrice' ? (portfolioSortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handlePortfolioSort('tokenFloor')}>
                                <div className="holders-header-content">
                                    Valuation (Token Floor)
                                    <ArrowUpDown className={`holders-sort-icon ${portfolioSortConfig.key === 'tokenFloor' ? (portfolioSortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handlePortfolioSort('collectionFloor')}>
                                <div className="holders-header-content">
                                    Valuation (Collection Floor)
                                    <ArrowUpDown className={`holders-sort-icon ${portfolioSortConfig.key === 'collectionFloor' ? (portfolioSortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handlePortfolioSort('lastSold')}>
                                <div className="holders-header-content">
                                    Valuation (Last Sold)
                                    <ArrowUpDown className={`holders-sort-icon ${portfolioSortConfig.key === 'lastSold' ? (portfolioSortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedPortfolioValuations.map((item, index) => (
                            <tr key={index}>
                                <td>{item.collection}</td>
                                <td>{item.bidPrice.toFixed(4)}</td>
                                <td>{item.tokenFloor.toFixed(4)}</td>
                                <td>{item.collectionFloor.toFixed(4)}</td>
                                <td>{item.lastSold.toFixed(4)}</td>
                            </tr>
                        ))}
                        <tr className="portfolio-grand-total-row">
                            <td><strong>Portfolio Total</strong></td>
                            <td><strong>{portfolioTotal.bidPrice.toFixed(4)}</strong></td>
                            <td><strong>{portfolioTotal.tokenFloor.toFixed(4)}</strong></td>
                            <td><strong>{portfolioTotal.collectionFloor.toFixed(4)}</strong></td>
                            <td><strong>{portfolioTotal.lastSold.toFixed(4)}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default HodlersIndividualsComponent; 