import React from 'react';
import { Link } from 'react-router-dom';
import './FooterComponent.css';

const FooterComponent = () => {
  return (
    <footer className="footer-container">
      <div className="footer-inner">
        <p className="creator-info">Created by Vantekai</p>

        <p className="donation-message">
          Support the site by sending tips to <strong>6529stats.eth</strong>
        </p>

        {/* New Paragraph for Additional Insights & Analysis */}
        <p className="additional-insights">
          Additional Insights &amp; Analysis:{' '}
          <a 
            href="https://vantekai.substack.com/" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            https://vantekai.substack.com/
          </a>
        </p>
        
        <div className="social-links">
          <a 
            href="https://x.com/Vantekai_" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            X: Vantekai
          </a>
          <a 
            href="https://6529.io/Vantekai" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            6529.io: Vantekai
          </a>
          <a href="mailto:admin@6529stats.xyz">
            admin@6529stats.xyz
          </a>
        </div>

        <div className="footer-contact-button">
          <Link to="/contact" className="green-button">
            Contact
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
