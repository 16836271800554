import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ArrowUpDown } from 'lucide-react';
import ReactPaginate from 'react-paginate';
import './HodlersComponent.css';
import { fetchHoldersAllData } from './apiService';
import { debounce } from 'lodash';

function HodlersComponent() {
    const [holdersData, setHoldersData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'tdh', direction: 'desc' });
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('All');
    const [secondaryFilterType, setSecondaryFilterType] = useState('All');
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [tdhLimit, setTdhLimit] = useState('');
    const [tdhCompare, setTdhCompare] = useState('gt');
    const [tdhValue, setTdhValue] = useState('');

    const itemsPerPage = 25;

    // Create memoized debounced search function
    const debouncedSearch = useMemo(
        () => debounce((value) => {
            if (!value.trim()) {
                setSearchSuggestions([]);
                return;
            }
            const fetchSuggestions = async () => {
                try {
                    const response = await fetch(`/api/search-profiles?query=${encodeURIComponent(value)}`);
                    const data = await response.json();
                    setSearchSuggestions(data);
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                    setSearchSuggestions([]);
                }
            };
            fetchSuggestions();
        }, 300),
        []
    );

    // Cleanup debounced function on unmount
    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    // Fetch data from backend
    const fetchData = useCallback(async () => {
        try {
            const response = await fetchHoldersAllData({
                page: currentPage + 1,
                pageSize: itemsPerPage,
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction,
                searchTerm: searchTerm,
                filterType: filterType,
                secondaryFilterType: secondaryFilterType,
                tdhLimit: tdhLimit,
                tdhCompare: tdhCompare,
                tdhValue: tdhValue
            });
            setHoldersData(response.data);
            setTotalRecords(response.totalCount);
        } catch (error) {
            console.error('Error fetching holders data:', error);
        }
    }, [currentPage, sortConfig, searchTerm, filterType, secondaryFilterType, tdhLimit, tdhCompare, tdhValue]);

    // Fetch data when filters, sorting, or pagination changes
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Handle sorting
    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
        setCurrentPage(0);
    };

    // Handle page change
    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    // Update handleSearchChange to include suggestions
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };

    // Handle suggestion selection
    const handleSuggestionSelect = (suggestion) => {
        setSearchTerm(suggestion);
        setSearchSuggestions([]);
        window.open(`/hodlers/${suggestion}`, '_blank');
    };

    // Handle search form submit
    const handleSearch = (e) => {
        e.preventDefault();
        fetchData();
    };

    // Handle filter change
    const handleFilterChange = (value) => {
        setFilterType(value);
        setCurrentPage(0);
    };

    // Handle secondary filter change
    const handleSecondaryFilterChange = (value) => {
        setSecondaryFilterType(value);
        setCurrentPage(0);
    };

    // Handle TDH limit change
    const handleTdhLimitChange = (value) => {
        setTdhLimit(value);
        setCurrentPage(0);
        fetchData(); // Immediately fetch data when TDH limit changes
    };

    // Handle TDH compare change
    const handleTdhCompareChange = (value) => {
        setTdhCompare(value);
        setCurrentPage(0);
        fetchData(); // Immediately fetch data when TDH compare changes
    };

    // Handle TDH value change
    const handleTdhValueChange = (value) => {
        setTdhValue(value);
        setCurrentPage(0);
        fetchData(); // Immediately fetch data when TDH value changes
    };

    // Handle clear
    const handleClear = () => {
        setSearchTerm('');
        setFilterType('All');
        setSecondaryFilterType('All');
        setTdhLimit('');
        setTdhCompare('gt');
        setTdhValue('');
        setCurrentPage(0);
        fetchData(); // Immediately fetch data when clearing filters
    };

    return (
        <div className="holders-main">
            <h1 className="holders-title">
                <span className="holders-title-white">6529</span> <span className="holders-title-accent">Hodlers</span>
            </h1>

            {/* Updated search section */}
            <div className="holders-search-section">
                <div className="holders-search-card">
                    <h2>Search Holder Profile</h2>
                    <div className="holders-search-container">
                        <div className="holders-search-input-wrapper">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Enter 6529 profile name or wallet address"
                                className="holders-search-input"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && searchTerm.trim()) {
                                        window.open(`/hodlers/${searchTerm.trim()}`, '_blank');
                                    }
                                }}
                            />
                            {searchSuggestions.length > 0 && searchTerm && (
                                <div className="holders-suggestions-dropdown">
                                    {searchSuggestions.map((suggestion, index) => (
                                        <div
                                            key={index}
                                            className="holders-suggestion-item"
                                            onClick={() => handleSuggestionSelect(suggestion)}
                                        >
                                            {suggestion}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <button 
                            className="holders-view-profile-btn"
                            onClick={() => {
                                if (searchTerm.trim()) {
                                    window.open(`/hodlers/${searchTerm.trim()}`, '_blank');
                                }
                            }}
                        >
                            View Profile
                        </button>
                    </div>
                </div>
            </div>

            {/* Filters Form */}
            <form className="holders-filters-form" onSubmit={handleSearch}>
                <div className="holders-filters-row">
                    <div className="holders-filter-item">
                        <label>Primary Collection</label>
                        <select
                            value={filterType}
                            onChange={(e) => handleFilterChange(e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="Memes Bal">Memes Bal</option>
                            <option value="Pebbles Bal">Pebbles Bal</option>
                            <option value="Mfers Bal">Mfers Bal</option>
                            <option value="Rektguy Bal">Rektguy Bal</option>
                        </select>
                    </div>
                    <div className="holders-filter-item">
                        <label>Secondary Collection</label>
                        <select
                            value={secondaryFilterType}
                            onChange={(e) => handleSecondaryFilterChange(e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="Memes Bal">Memes Bal</option>
                            <option value="Pebbles Bal">Pebbles Bal</option>
                            <option value="Mfers Bal">Mfers Bal</option>
                            <option value="Rektguy Bal">Rektguy Bal</option>
                        </select>
                    </div>
                    <div className="holders-filter-item">
                        <label>Top TDH Profiles</label>
                        <input
                            type="number"
                            min="0"
                            placeholder="Enter number (e.g. 100)"
                            value={tdhLimit || ''}
                            onChange={(e) => handleTdhLimitChange(e.target.value)}
                            className="holders-filter-input"
                        />
                    </div>
                    <div className="holders-filter-item">
                        <label>TDH Value Filter</label>
                        <div className="tdh-value-filter">
                            <select
                                value={tdhCompare || 'gt'}
                                onChange={(e) => handleTdhCompareChange(e.target.value)}
                                className="holders-filter-select"
                            >
                                <option value="gt">Greater than</option>
                                <option value="lt">Less than</option>
                                <option value="eq">Equal to</option>
                            </select>
                            <input
                                type="number"
                                min="0"
                                placeholder="Enter TDH value"
                                value={tdhValue || ''}
                                onChange={(e) => handleTdhValueChange(e.target.value)}
                                className="holders-filter-input"
                            />
                        </div>
                    </div>
                </div>

                {/* Action buttons and record count */}
                <div className="holders-action-buttons-row">
                    <div className="holders-buttons-group">
                        <button type="submit">Search</button>
                        <button type="button" onClick={handleClear}>Clear</button>
                    </div>
                    <span className="holders-records-count">
                        <strong>Number of Records:</strong> {totalRecords}
                    </span>
                </div>
            </form>

            {/* Table */}
            <table className="holders-table">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Profile / Address</th>
                        <th onClick={() => handleSort('tdh')}>
                            <div className="holders-header-content">
                                TDH
                                <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'tdh' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => handleSort('overall_balance_of_cards')}>
                            <div className="holders-header-content">
                                Memes Bal
                                <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'overall_balance_of_cards' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => handleSort('overall_num_unique_cards')}>
                            <div className="holders-header-content">
                                Unique Memes
                                <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'overall_num_unique_cards' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => handleSort('overall_num_full_sets')}>
                            <div className="holders-header-content">
                                Memes Sets
                                <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'overall_num_full_sets' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => handleSort('pebblesbalance')}>
                            <div className="holders-header-content">
                                Pebbles Bal
                                <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'pebblesbalance' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => handleSort('mfersbalance')}>
                            <div className="holders-header-content">
                                Mfers Bal
                                <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'mfersbalance' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => handleSort('rektguybalance')}>
                            <div className="holders-header-content">
                                Rektguy Bal
                                <ArrowUpDown className={`holders-sort-icon ${sortConfig.key === 'rektguybalance' ? (sortConfig.direction === 'asc' ? 'holders-sorted-asc' : 'holders-sorted-desc') : ''}`} />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {holdersData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.global_rank || "Updating"}</td>
                            <td>
                                {item.custom_profile ? (
                                    <a 
                                        href={`/hodlers/${item.custom_profile}`}
                                        className="owner-link"
                                    >
                                        {item.custom_profile.startsWith('0x') 
                                            ? `${item.custom_profile.substring(0, 6)}...${item.custom_profile.substring(item.custom_profile.length - 4)}`
                                            : item.custom_profile}
                                    </a>
                                ) : (
                                    "Updating - Check back tomorrow"
                                )}
                            </td>
                            <td>{item.tdh || "Updating - Check back tomorrow"}</td>
                            <td>{item.overall_balance_of_cards || 0}</td>
                            <td>{item.overall_num_unique_cards || 0}</td>
                            <td>{item.overall_num_full_sets || 0}</td>
                            <td>{item.pebblesbalance || 0}</td>
                            <td>{item.mfersbalance || 0}</td>
                            <td>{item.rektguybalance || 0}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            <ReactPaginate
                previousLabel={'← Previous'}
                nextLabel={'Next →'}
                breakLabel={'...'}
                pageCount={Math.ceil(totalRecords / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'holders-pagination'}
                activeClassName={'active'}
            />
        </div>
    );
}

export default HodlersComponent; 