// HodlerSupplyComponent.js

import React, { useState, useEffect } from 'react';
import './HodlerSupplyComponent.css';
import { fetchHodlerSupplyData } from './apiService';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa'; // Import sort icons

const HodlerSupplyComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: 'Token',
    direction: 'ascending',
  });
  const [filterConfig, setFilterConfig] = useState({
    szn: 'All',
  });
  const [sznOptions, setSznOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchHodlerSupplyData();
        setData(response.data);

        // Get unique TypeSeason values for the filter dropdown
        const uniqueSzn = [...new Set(response.data.map((item) => item.TypeSeason))];
        uniqueSzn.sort((a, b) => {
          const numA = parseInt(a.replace('SZN', ''));
          const numB = parseInt(b.replace('SZN', ''));
          return numA - numB;
        });
        setSznOptions(uniqueSzn);
      } catch (error) {
        console.error('Error fetching hodler supply data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableItems = [...data];

    // Apply filter
    if (filterConfig.szn !== 'All') {
      sortableItems = sortableItems.filter((item) => item.TypeSeason === filterConfig.szn);
    }

    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Handle null or undefined values
        if (aValue === null || aValue === undefined) aValue = 0;
        if (bValue === null || bValue === undefined) bValue = 0;

        if (typeof aValue === 'string' && sortConfig.key !== 'Name') {
          aValue = parseFloat(aValue);
        }
        if (typeof bValue === 'string' && sortConfig.key !== 'Name') {
          bValue = parseFloat(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig, filterConfig]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return <FaSortUp />;
      } else {
        return <FaSortDown />;
      }
    } else {
      return <FaSort />; // Neutral sort icon to indicate sortable
    }
  };

  return (
    <div className="hodler-supply-container">
      <h1 className="hodler-main-header">Hodler Supply</h1>
      <div className="hodler-filter-container">
        <div>
          <label htmlFor="szn-filter">Filter by SZN: </label>
          <select
            id="szn-filter"
            value={filterConfig.szn}
            onChange={(e) => setFilterConfig({ ...filterConfig, szn: e.target.value })}
          >
            <option value="All">All</option>
            {sznOptions.map((szn) => (
              <option key={szn} value={szn}>
                {szn}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="hodler-explanations">
        <p>
          <strong>**</strong> This includes 6529 Museum, 6529.eth, social.6529.eth, trove.6529.eth, and
          research.6529.eth
        </p>
        <p>
          <strong>***</strong> This includes the above as well as full set holders for the SZN the card is in
        </p>
        <p>This page updates daily at 1am UTC</p>
      </div>
      <div className="hodler-table-wrapper">
        <table className="hodler-centered-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('Token')}>
                Card No {getSortIcon('Token')}
              </th>
              <th>Name</th>
              <th>SZN</th>
              <th onClick={() => handleSort('FloorPrice')}>
                Lowest Ask {getSortIcon('FloorPrice')}
              </th>
              <th onClick={() => handleSort('HighestBid')}>
                Highest Bid {getSortIcon('HighestBid')}
              </th>
              <th onClick={() => handleSort('LastSalePrice')}>
                Last Sale Price {getSortIcon('LastSalePrice')}
              </th>
              <th onClick={() => handleSort('Supply')}>
                Supply {getSortIcon('Supply')}
              </th>
              <th onClick={() => handleSort('Supply_Excl_6529_Museum')}>
                Supply Excl 6529 Eco ** {getSortIcon('Supply_Excl_6529_Museum')}
              </th>
              <th onClick={() => handleSort('Supply_Excl_6529_Museum_IndivSZNholder')}>
                Supply Excl 6529 Eco Set Hodlers ***{' '}
                {getSortIcon('Supply_Excl_6529_Museum_IndivSZNholder')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item) => (
              <tr key={item.Token}>
                <td>{item.Token}</td>
                <td>{item.Name}</td>
                <td>{item.TypeSeason}</td>
                <td>{item.FloorPrice}</td>
                <td>{item.HighestBid}</td>
                <td>{item.LastSalePrice}</td>
                <td>{item.Supply}</td>
                <td>{item.Supply_Excl_6529_Museum}</td>
                <td>{item.Supply_Excl_6529_Museum_IndivSZNholder}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HodlerSupplyComponent;
