// TokenComponent.js

import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchTokenData, fetchOwnerCount, fetchCommunityMembersData } from './apiService';
import './TokenComponent.css';
import openseaIcon from './assets/opensea.png';
import seizeIcon from './assets/seize.png';
import etherscanIcon from './assets/etherscan.png';
import { CSVLink } from 'react-csv';

const TokenComponent = () => {
    const { tokenid } = useParams();
    const [tokenData, setTokenData] = useState(null);
    const [ownerCount, setOwnerCount] = useState(null);
    const [communityMembers, setCommunityMembers] = useState(null);
    const [supplyExcl, setSupplyExcl] = useState(null);
    const [supplyExclFullSetHolders, setSupplyExclFullSetHolders] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getTokenData = async () => {
            try {
                const data = await fetchTokenData(tokenid);
                setTokenData(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching token data:', error);
                setLoading(false);
            }
        };
        getTokenData();
    }, [tokenid]);

    useEffect(() => {
        const getOwnerCount = async () => {
            try {
                const count = await fetchOwnerCount(tokenid);
                setOwnerCount(count);
            } catch (error) {
                console.error('Error fetching owner count:', error);
            }
        };
        getOwnerCount();
    }, [tokenid]);

    useEffect(() => {
        const getCommunityMembers = async () => {
            try {
                const data = await fetchCommunityMembersData(tokenid);
                setCommunityMembers(data);
            } catch (error) {
                console.error('Error fetching community members data:', error);
            }
        };
        getCommunityMembers();
    }, [tokenid]);

    useEffect(() => {
        if (tokenData && communityMembers) {
            const supply = parseInt(tokenData.tokenInfo.supply, 10) || 0;

            // Define the consolidation keys to exclude
            const excludedConsolidationKeys = [
                "0x32b8bce7cf8c6203a841b0d08bde5ae89f0af103-0xc2ce4ccef11a8171f443745cea3bceeaadd750c7",
                "0x6daa633c23615a29471deafae351727867e7dad1-0xa094ca13e855c3e926f9069ee140602ace421fe3-0xfd22004806a6846ea67ad883356be810f0428793",
                "0x924817154d311bd2de599f3d659d053ab0133e06-0xc6400a5584db71e41b0e5dfbdc769b54b91256cd"
            ];

            // Calculate sum of balances for excluded consolidation keys
            const sumExcludedBalances = communityMembers.holders
                .filter(holder => excludedConsolidationKeys.includes(holder.consolidation_key))
                .reduce((acc, holder) => acc + (holder.balance || 0), 0);

            // Calculate Supply (Excl ...)
            const supplyExclCalc = supply - sumExcludedBalances;
            setSupplyExcl(supplyExclCalc >= 0 ? supplyExclCalc : 0);

            // Calculate number of holders with memes_cards_sets >=1 excluding excluded consolidation keys
            const holdersFullSet = communityMembers.holders
                .filter(holder => 
                    !excludedConsolidationKeys.includes(holder.consolidation_key) && 
                    holder.memes_cards_sets && 
                    holder.memes_cards_sets >= 1
                ).length;

            // Calculate Supply (Excl ... and full set holders)
            const supplyExclFullSetHoldersCalc = supplyExclCalc - holdersFullSet;
            setSupplyExclFullSetHolders(
                supplyExclFullSetHoldersCalc >= 0 ? supplyExclFullSetHoldersCalc : 0
            );
        }
    }, [tokenData, communityMembers]);

    // Memoize bids, asks, and sales
    const bids = useMemo(() => tokenData?.bids || [], [tokenData]);
    const asks = useMemo(() => tokenData?.asks || [], [tokenData]);
    const sales = useMemo(() => tokenData?.sales || [], [tokenData]);

    // Prepare CSV data for Bids
    const bidsCsvData = useMemo(() => {
        const headers = [
            { label: "Amount (ETH)", key: "amount_eth" },
            { label: "Bidder", key: "maker" },
            { label: "Quantity", key: "quantity" },
            { label: "Bid Type", key: "criteria_kind" },
            { label: "Marketplace", key: "source_name" }
        ];

        const data = bids.map(bid => ({
            amount_eth: bid.amount_eth,
            maker: bid.maker,
            quantity: bid.quantity,
            criteria_kind: bid.criteria_kind,
            source_name: bid.source_name || 'N/A'
        }));

        return { headers, data };
    }, [bids]);

    // Prepare CSV data for Asks
    const asksCsvData = useMemo(() => {
        const headers = [
            { label: "Amount (ETH)", key: "amount_eth" },
            { label: "Seller", key: "maker" },
            { label: "Quantity", key: "quantity" },
            { label: "Marketplace", key: "source_name" }
        ];

        const data = asks.map(ask => ({
            amount_eth: ask.amount_eth,
            maker: ask.maker,
            quantity: ask.quantity,
            source_name: ask.source_name || 'N/A'
        }));

        return { headers, data };
    }, [asks]);

    // Prepare CSV data for Sales
    const salesCsvData = useMemo(() => {
        const headers = [
            { label: "Date", key: "date" },
            { label: "Price (USD)", key: "price_usd" },
            { label: "Price (ETH)", key: "price_eth" },
            { label: "Seller", key: "seller" },
            { label: "Buyer", key: "buyer" },
            { label: "Tx Hash", key: "txhash" }
        ];

        const data = sales.map(sale => ({
            date: sale.date,
            price_usd: sale.price_usd,
            price_eth: sale.price_eth,
            seller: sale.seller,
            buyer: sale.buyer,
            txhash: sale.txhash
        }));

        return { headers, data };
    }, [sales]);

    // Early returns
    if (loading) {
        return <div className="spinner"></div>;
    }

    if (!tokenData) {
        return <div>Error loading token data.</div>;
    }

    // Destructure data for easier access
    const { tokenInfo, salesPrices } = tokenData;

    // Calculate Top Bid
    const topBid = bids.length > 0 ? Math.max(...bids.map(bid => parseFloat(bid.amount_eth))) : null;

    // Calculate Lowest Ask
    const lowestAsk = asks.length > 0 ? Math.min(...asks.map(ask => parseFloat(ask.amount_eth))) : null;

    // Get Last Sale
    const lastSale = sales.length > 0 ? parseFloat(sales[0].price_eth) : null;

    // Define the OpenSea Token URL
    const openSeaTokenUrl = `https://opensea.io/assets/ethereum/0x33fd426905f149f8376e227d0c9d3340aad17af1/${tokenInfo.tokenid}`;

    return (
        <div className="token-container">
            {/* Header Section */}
            <div className="token-header">
                <h1>
                    {`6529 Meme Card ${tokenInfo.tokenid}. ${tokenInfo.name}`}
                    {/* OpenSea Icon */}
                    <a
                        href={openSeaTokenUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={openseaIcon} alt="OpenSea" className="marketplace-icon" />
                    </a>
                    {/* Seize Icon */}
                    <a
                        href={`https://seize.io/the-memes/${tokenInfo.tokenid}?focus=live`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={seizeIcon} alt="Seize" className="marketplace-icon" />
                    </a>
                </h1>
                <p>
                    {`Token No: ${tokenInfo.tokenid} | Season: ${tokenInfo.typeseason} | Supply: ${tokenInfo.supply} | Artist: ${tokenInfo.artist} | `}
                    <Link to={`/token/${tokenid}/owners`} className="owners-link">
                        <strong>Owners: {ownerCount !== null ? ownerCount : '...'}</strong>
                    </Link>
                </p>
            </div>

            {/* New Metrics Section */}
            <div className="new-metrics-section">
                <div className="metric-box">
                    <h3>Supply</h3>
                    <p>{tokenInfo.supply}</p>
                </div>
                <div className="metric-box">
                    <h3>Supply (Excl 6529 Museum, 6529.eth, research.eth, social.6529.eth, and trove.6529.eth)</h3>
                    <p>{supplyExcl !== null ? supplyExcl : '...'}</p>
                </div>
                <div className="metric-box">
                    <h3>Supply (Excl 6529 Museum, 6529.eth, research.eth, and full set holders)</h3>
                    <p>{supplyExclFullSetHolders !== null ? supplyExclFullSetHolders : '...'}</p>
                </div>
            </div>

            {/* Prices Section */}
            <div className="prices-section">
                {/* First Row of Boxes */}
                <div className="price-box">
                    <h3>Highest Sale Price Ever</h3>
                    <p>{salesPrices.highest_sale_usd !== null ? 
                        `$${salesPrices.highest_sale_usd.toLocaleString()} / ${salesPrices.highest_sale_eth} ETH` : 
                        'No sales yet'}</p>
                </div>
                <div className="price-box">
                    <h3>Lowest Sale Price Ever</h3>
                    <p>{salesPrices.lowest_sale_usd !== null ? 
                        `$${salesPrices.lowest_sale_usd.toLocaleString()} / ${salesPrices.lowest_sale_eth} ETH` : 
                        'No sales yet'}</p>
                </div>
                <div className="price-box">
                    <h3>Current PPTDH</h3>
                    <p>{tokenInfo.pptdh || 'N/A'}</p>
                </div>
            </div>

            {/* Additional Prices Section */}
            <div className="prices-section">
                {/* Second Row of Boxes */}
                <div className="price-box">
                    <h3>Top Bid</h3>
                    <p>{topBid ? `${topBid.toFixed(5)} ETH` : 'N/A'}</p>
                </div>
                <div className="price-box">
                    <h3>Lowest Ask</h3>
                    <p>{lowestAsk ? `${lowestAsk.toFixed(5)} ETH` : 'N/A'}</p>
                </div>
                <div className="price-box">
                    <h3>Last Sale</h3>
                    <p>{lastSale ? `${lastSale.toFixed(5)} ETH` : 'N/A'}</p>
                </div>
            </div>

            {/* Bids Table */}
            <div className="table-section">
                <h2>
                    Bids <span className="update-info">(Bids updated approx every 2 minutes)</span>
                </h2>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Amount (ETH)</th>
                                <th>Bidder</th>
                                <th>Quantity</th>
                                <th>Bid Type</th>
                                <th>Marketplace</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bids.map((bid, index) => (
                                <tr key={index}>
                                    <td>{bid.amount_eth}</td>
                                    <td>{bid.maker}</td>
                                    <td>{bid.quantity}</td>
                                    <td>{bid.criteria_kind}</td>
                                    <td>
                                        {/* Marketplace Link */}
                                        <a
                                            href={openSeaTokenUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {bid.source_icon ? (
                                                <img src={bid.source_icon} alt="Marketplace" className="marketplace-table-icon" />
                                            ) : (
                                                'OpenSea'
                                            )}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Centered Download CSV Button for Bids */}
                <div className="download-button">
                    <CSVLink
                        data={bidsCsvData.data}
                        headers={bidsCsvData.headers}
                        filename={`token_${tokenid}_bids.csv`}
                        className="btn"
                    >
                        Download CSV
                    </CSVLink>
                </div>
            </div>

            {/* Asks Table */}
            <div className="table-section">
                <h2>
                    Asks <span className="update-info">(Asks updated approx every 2 minutes)</span>
                </h2>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Amount (ETH)</th>
                                <th>Seller</th>
                                <th>Quantity</th>
                                <th>Marketplace</th>
                            </tr>
                        </thead>
                        <tbody>
                            {asks.map((ask, index) => (
                                <tr key={index}>
                                    <td>{ask.amount_eth}</td>
                                    <td>{ask.maker}</td>
                                    <td>{ask.quantity}</td>
                                    <td>
                                        {ask.source_icon && ask.source_url ? (
                                            <a href={ask.source_url} target="_blank" rel="noopener noreferrer">
                                                <img src={ask.source_icon} alt={ask.source_name} className="marketplace-table-icon" />
                                            </a>
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Centered Download CSV Button for Asks */}
                <div className="download-button">
                    <CSVLink
                        data={asksCsvData.data}
                        headers={asksCsvData.headers}
                        filename={`token_${tokenid}_asks.csv`}
                        className="btn"
                    >
                        Download CSV
                    </CSVLink>
                </div>
            </div>

            {/* Sales Table */}
            <div className="table-section">
                <h2>Most Recent Sales</h2>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Price (USD)</th>
                                <th>Price (ETH)</th>
                                <th>Seller</th>
                                <th>Buyer</th>
                                <th>Tx</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sales.length > 0 ? (
                                sales.map((sale, index) => (
                                    <tr key={index}>
                                        <td>{new Date(sale.date).toLocaleDateString()}</td>
                                        <td>{sale.price_usd ? `$${sale.price_usd.toLocaleString()}` : 'N/A'}</td>
                                        <td>{sale.price_eth || 'N/A'}</td>
                                        <td>{sale.seller || 'N/A'}</td>
                                        <td>{sale.buyer || 'N/A'}</td>
                                        <td>
                                            {sale.txhash ? (
                                                <a
                                                    href={`https://etherscan.io/tx/${sale.txhash}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img src={etherscanIcon} alt="Etherscan" className="marketplace-table-icon" />
                                                </a>
                                            ) : 'N/A'}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" style={{textAlign: 'center'}}>No sales data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {/* Centered Download CSV Button for Sales */}
                <div className="download-button">
                    <CSVLink
                        data={salesCsvData.data}
                        headers={salesCsvData.headers}
                        filename={`token_${tokenid}_sales.csv`}
                        className="btn"
                    >
                        Download CSV
                    </CSVLink>
                </div>
            </div>
        </div>
    );
};

export default TokenComponent;
