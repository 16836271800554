import React, { useState, useEffect, useCallback } from 'react';
import { ArrowUpDown } from 'lucide-react';
import ReactPaginate from 'react-paginate';
import { fetchPebblesSalesData, fetchPebblesMainData } from './apiService';
import './PebblesSalesComponent.css';

// Import images
import etherscanIcon from './assets/etherscan.png';
import seizeIcon from './assets/seizeIcon.png';

function PebblesSalesComponent() {
    const [salesData, setSalesData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [neverSoldCount, setNeverSoldCount] = useState(0);
    const [numberForSale, setNumberForSale] = useState(0);
    const [filterOptions, setFilterOptions] = useState({
        palette: [],
        size: [],
        color_density: [],
        overlap: [],
        special_elements: [],
        traced: [],
        edge: [],
        flow: [],
        mint_type_palettes: []
    });
    const [filters, setFilters] = useState({
        palette: 'All',
        size: 'All',
        color_density: 'All',
        overlap: 'All',
        special_elements: 'All',
        traced: 'All',
        edge: 'All',
        flow: 'All',
        mint_type_palettes: 'All',
        token_id: ''
    });
    const itemsPerPage = 25;

    // Fetch all unique filter options
    const fetchFilterOptions = async () => {
        try {
            const response = await fetchPebblesSalesData({
                page: 1,
                pageSize: 1000
            });
            
            const options = {
                palette: new Set(),
                size: new Set(),
                color_density: new Set(),
                overlap: new Set(),
                special_elements: new Set(),
                traced: new Set(),
                edge: new Set(),
                flow: new Set(),
                mint_type_palettes: new Set()
            };

            response.data.forEach(item => {
                options.palette.add(item.attribute_palette);
                options.size.add(item.attribute_size);
                options.color_density.add(item.attribute_color_density);
                options.overlap.add(item.attribute_overlap);
                options.special_elements.add(item.attribute_special_elements);
                options.traced.add(item.attribute_traced);
                options.edge.add(item.attribute_edge);
                options.flow.add(item.attribute_flow);
                options.mint_type_palettes.add(item.mint_type_palettes);
            });

            // Convert Sets to sorted arrays and add 'All' option
            const sortedOptions = {};
            Object.keys(options).forEach(key => {
                sortedOptions[key] = ['All', ...Array.from(options[key])
                    .filter(Boolean)
                    .sort((a, b) => a.localeCompare(b))];
            });

            setFilterOptions(sortedOptions);
        } catch (error) {
            console.error('Error fetching filter options:', error);
        }
    };

    // Fetch data from backend
    const fetchData = useCallback(async () => {
        try {
            const mainResponse = await fetchPebblesMainData({
                page: 1,
                pageSize: 1
            });
            setNumberForSale(mainResponse.number_for_sale);

            const response = await fetchPebblesSalesData({
                page: currentPage + 1,
                pageSize: itemsPerPage,
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction,
                ...filters
            });
            setSalesData(response.data);
            setTotalRecords(response.totalCount);
            setNeverSoldCount(response.neverSoldCount);
        } catch (error) {
            console.error('Error fetching pebbles sales data:', error);
        }
    }, [currentPage, sortConfig, filters, itemsPerPage]);

    useEffect(() => {
        fetchFilterOptions();
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Implement sorting
    const sortTable = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCurrentPage(0);
    };

    // Handle page change
    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    // Handle filter change
    const handleFilterChange = (filterName, value) => {
        setFilters(prev => ({ ...prev, [filterName]: value }));
        setCurrentPage(0);
    };

    // Handle search/clear
    const handleSearch = (e) => {
        e.preventDefault();
        fetchData();
    };

    const handleClear = () => {
        setFilters({
            palette: 'All',
            size: 'All',
            color_density: 'All',
            overlap: 'All',
            special_elements: 'All',
            traced: 'All',
            edge: 'All',
            flow: 'All',
            mint_type_palettes: 'All',
            token_id: ''
        });
        setCurrentPage(0);
    };

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        };
        return new Date(dateString).toLocaleString(undefined, options);
    };

    return (
        <div className="pebbles-sales">
            <h1 className="pebbles-sales-title">
                Pebbles by <a href="https://6529.io/zeblocks/rep" target="_blank" rel="noopener noreferrer">Zeblocks</a>
            </h1>

            {/* KPI Dashboard */}
            <div className="pebbles-sales-dashboard">
                <div className="pebbles-sales-kpi-box">
                    <h3>No Of Pebbles Never Sold</h3>
                    <p className="pebbles-sales-kpi-value">{neverSoldCount} ({((neverSoldCount / 1000) * 100).toFixed(1)}%)</p>
                </div>
                <div className="pebbles-sales-kpi-box">
                    <a 
                        href="https://6529stats.xyz/nextgen/pebbles/prices" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="pebbles-sales-kpi-link"
                    >
                        <h3>No Of Pebbles For Sale</h3>
                    </a>
                    <p className="pebbles-sales-kpi-value">{numberForSale} ({((numberForSale / 1000) * 100).toFixed(1)}%)</p>
                </div>
            </div>

            {/* Filters Form */}
            <form className="pebbles-sales-filters-form" onSubmit={handleSearch}>
                {/* First row of filters */}
                <div className="pebbles-sales-filters-row">
                    <div className="pebbles-sales-filter-item">
                        <label>Token ID</label>
                        <input
                            type="text"
                            value={filters.token_id}
                            onChange={(e) => handleFilterChange('token_id', e.target.value)}
                            placeholder="Enter token ID..."
                        />
                    </div>
                    <div className="pebbles-sales-filter-item">
                        <label>Palette</label>
                        <select
                            value={filters.palette}
                            onChange={(e) => handleFilterChange('palette', e.target.value)}
                        >
                            {filterOptions.palette.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-sales-filter-item">
                        <label>Size</label>
                        <select
                            value={filters.size}
                            onChange={(e) => handleFilterChange('size', e.target.value)}
                        >
                            {filterOptions.size.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-sales-filter-item">
                        <label>Colour Density</label>
                        <select
                            value={filters.color_density}
                            onChange={(e) => handleFilterChange('color_density', e.target.value)}
                        >
                            {filterOptions.color_density.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Second row of filters */}
                <div className="pebbles-sales-filters-row">
                    <div className="pebbles-sales-filter-item">
                        <label>Overlap</label>
                        <select
                            value={filters.overlap}
                            onChange={(e) => handleFilterChange('overlap', e.target.value)}
                        >
                            {filterOptions.overlap.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-sales-filter-item">
                        <label>Special Elements</label>
                        <select
                            value={filters.special_elements}
                            onChange={(e) => handleFilterChange('special_elements', e.target.value)}
                        >
                            {filterOptions.special_elements.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-sales-filter-item">
                        <label>Traced</label>
                        <select
                            value={filters.traced}
                            onChange={(e) => handleFilterChange('traced', e.target.value)}
                        >
                            {filterOptions.traced.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-sales-filter-item">
                        <label>Edge</label>
                        <select
                            value={filters.edge}
                            onChange={(e) => handleFilterChange('edge', e.target.value)}
                        >
                            {filterOptions.edge.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Third row of filters */}
                <div className="pebbles-sales-filters-row">
                    <div className="pebbles-sales-filter-item">
                        <label>Flow</label>
                        <select
                            value={filters.flow}
                            onChange={(e) => handleFilterChange('flow', e.target.value)}
                        >
                            {filterOptions.flow.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-sales-filter-item">
                        <label>Mint Type Palettes</label>
                        <select
                            value={filters.mint_type_palettes}
                            onChange={(e) => handleFilterChange('mint_type_palettes', e.target.value)}
                        >
                            {filterOptions.mint_type_palettes.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Action buttons and record count */}
                <div className="pebbles-sales-action-buttons-row">
                    <div className="pebbles-sales-buttons-group">
                        <button type="submit">Search</button>
                        <button type="button" onClick={handleClear}>Clear</button>
                    </div>
                    <span className="pebbles-sales-records-count">
                        <strong>Number of Records:</strong> {totalRecords}
                    </span>
                </div>
            </form>

            {/* Table */}
            <table className="pebbles-sales-table">
                <thead>
                    <tr>
                        <th onClick={() => sortTable('token_id')}>
                            <div className="pebbles-sales-header-content">
                                No
                                <ArrowUpDown className={`pebbles-sales-sort-icon ${sortConfig.key === 'token_id' ? (sortConfig.direction === 'asc' ? 'pebbles-sales-sorted-asc' : 'pebbles-sales-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th>Image</th>
                        <th>Name</th>
                        <th onClick={() => sortTable('order_side')}>
                            <div className="pebbles-sales-header-content">
                                Sale Type
                                <ArrowUpDown className={`pebbles-sales-sort-icon ${sortConfig.key === 'order_side' ? (sortConfig.direction === 'asc' ? 'pebbles-sales-sorted-asc' : 'pebbles-sales-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => sortTable('order_source')}>
                            <div className="pebbles-sales-header-content">
                                Market
                                <ArrowUpDown className={`pebbles-sales-sort-icon ${sortConfig.key === 'order_source' ? (sortConfig.direction === 'asc' ? 'pebbles-sales-sorted-asc' : 'pebbles-sales-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => sortTable('decimal')}>
                            <div className="pebbles-sales-header-content">
                                Amount (ETH)
                                <ArrowUpDown className={`pebbles-sales-sort-icon ${sortConfig.key === 'decimal' ? (sortConfig.direction === 'asc' ? 'pebbles-sales-sorted-asc' : 'pebbles-sales-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => sortTable('usd')}>
                            <div className="pebbles-sales-header-content">
                                Amount (USD)
                                <ArrowUpDown className={`pebbles-sales-sort-icon ${sortConfig.key === 'usd' ? (sortConfig.direction === 'asc' ? 'pebbles-sales-sorted-asc' : 'pebbles-sales-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th>Edge</th>
                        <th>Traced</th>
                        <th>Flow</th>
                        <th>Size</th>
                        <th>Overlap</th>
                        <th>Special Elements</th>
                        <th>Color Density</th>
                        <th>Palette</th>
                        <th>Mint Type</th>
                        <th>Seller</th>
                        <th>Buyer</th>
                        <th onClick={() => sortTable('created_at')}>
                            <div className="pebbles-sales-header-content">
                                Date
                                <ArrowUpDown className={`pebbles-sales-sort-icon ${sortConfig.key === 'created_at' ? (sortConfig.direction === 'asc' ? 'pebbles-sales-sorted-asc' : 'pebbles-sales-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th>Tx</th>
                        <th>6529</th>
                    </tr>
                </thead>
                <tbody>
                    {salesData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.token_id}</td>
                            <td>
                                <img
                                    src={item.image}
                                    alt={item.name}
                                    className="pebbles-sales-item-image"
                                />
                            </td>
                            <td>{item.name}</td>
                            <td>{item.order_side}</td>
                            <td>{item.order_source}</td>
                            <td>{parseFloat(item.decimal).toFixed(5)}</td>
                            <td>${parseFloat(item.usd).toFixed(2)}</td>
                            <td>{item.attribute_edge}</td>
                            <td>{item.attribute_traced}</td>
                            <td>{item.attribute_flow}</td>
                            <td>{item.attribute_size}</td>
                            <td>{item.attribute_overlap}</td>
                            <td>{item.attribute_special_elements}</td>
                            <td>{item.attribute_color_density}</td>
                            <td>{item.attribute_palette}</td>
                            <td>{item.mint_type_palettes}</td>
                            <td>
                                <a
                                    href={`https://6529.io/id-${item.seller}/identity`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {item.seller.slice(0, 6)}...{item.seller.slice(-4)}
                                </a>
                            </td>
                            <td>
                                <a
                                    href={`https://6529.io/id-${item.buyer}/identity`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {item.buyer.slice(0, 6)}...{item.buyer.slice(-4)}
                                </a>
                            </td>
                            <td>{formatDate(item.created_at)}</td>
                            <td>
                                <a
                                    href={`https://etherscan.io/tx/${item.tx_hash}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={etherscanIcon} alt="Etherscan" className="pebbles-sales-icon-image" />
                                </a>
                            </td>
                            <td>
                                <a
                                    href={`https://6529.io/nextgen/token/${item.token_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={seizeIcon} alt="6529" className="pebbles-sales-icon-image" />
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            <div className="pebbles-sales-pagination">
                <ReactPaginate
                    previousLabel={'← Previous'}
                    nextLabel={'Next →'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(totalRecords / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pebbles-sales-pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    );
}

export default PebblesSalesComponent; 