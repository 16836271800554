import React, { useState, useEffect } from 'react';
import { ArrowUpDown } from 'lucide-react';
import ReactPaginate from 'react-paginate';
import './PebblesMainComponent.css';
import { fetchPebblesMainData, fetchPebblesSalesData } from './apiService';

// Import icons
import seizeIcon from './assets/seizeIcon.png';
import openseaIcon from './assets/openseaIcon.png';
import blurIcon from './assets/blurIcon.png';

function PebblesMainComponent() {
    const [pebblesData, setPebblesData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'token_id', direction: 'asc' });
    const [topBidMarketCap, setTopBidMarketCap] = useState(0);
    const [numberForSale, setNumberForSale] = useState(0);
    const [floorPrice, setFloorPrice] = useState(null);
    const [neverSoldCount, setNeverSoldCount] = useState(0);
    const [filterOptions, setFilterOptions] = useState({
        palette: [],
        size: [],
        color_density: [],
        overlap: [],
        special_elements: [],
        traced: [],
        edge: [],
        flow: [],
        mint_type_palettes: []
    });
    const [filters, setFilters] = useState({
        palette: 'All',
        size: 'All',
        color_density: 'All',
        overlap: 'All',
        special_elements: 'All',
        traced: 'All',
        edge: 'All',
        flow: 'All',
        mint_type_palettes: 'All',
        sale_status: 'All',
        token_id: '',
        custom_profile: ''
    });

    const itemsPerPage = 25;

    // Function to format date
    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        const date = new Date(parseInt(timestamp) * 1000);
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    // Fetch all unique filter options
    const fetchFilterOptions = async () => {
        try {
            // Fetch all records without pagination to get all possible values
            const response = await fetchPebblesMainData({
                page: 1,
                pageSize: 1000 // Fetch all records
            });
            
            // Extract unique values for each attribute
            const options = {
                palette: new Set(),
                size: new Set(),
                color_density: new Set(),
                overlap: new Set(),
                special_elements: new Set(),
                traced: new Set(),
                edge: new Set(),
                flow: new Set(),
                mint_type_palettes: new Set()
            };

            response.data.forEach(item => {
                options.palette.add(item.attribute_palette);
                options.size.add(item.attribute_size);
                options.color_density.add(item.attribute_color_density);
                options.overlap.add(item.attribute_overlap);
                options.special_elements.add(item.attribute_special_elements);
                options.traced.add(item.attribute_traced);
                options.edge.add(item.attribute_edge);
                options.flow.add(item.attribute_flow);
                options.mint_type_palettes.add(item.mint_type_palettes);
            });

            // Convert Sets to sorted arrays and add 'All' option
            const sortedOptions = {};
            Object.keys(options).forEach(key => {
                sortedOptions[key] = ['All', ...Array.from(options[key])
                    .filter(Boolean)
                    .sort((a, b) => a.localeCompare(b))];
            });

            setFilterOptions(sortedOptions);
        } catch (error) {
            console.error('Error fetching filter options:', error);
        }
    };

    // Fetch paginated data from backend
    const fetchData = async () => {
        try {
            const salesResponse = await fetchPebblesSalesData({
                page: 1,
                pageSize: 1
            });
            setNeverSoldCount(salesResponse.neverSoldCount);

            const response = await fetchPebblesMainData({
                page: currentPage + 1,
                pageSize: itemsPerPage,
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction,
                ...filters
            });
            setPebblesData(response.data);
            setTotalRecords(response.totalCount);
            setTopBidMarketCap(response.top_bid_market_cap);
            setNumberForSale(response.number_for_sale);
            setFloorPrice(response.floor_price);
        } catch (error) {
            console.error('Error fetching pebbles data:', error);
        }
    };

    // Fetch filter options when component mounts
    useEffect(() => {
        fetchFilterOptions();
    }, []);

    // Fetch data when filters, sorting, or pagination changes
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, sortConfig, filters]);

    // Handle sorting
    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
        setCurrentPage(0);
    };

    // Handle page change
    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    // Handle filter change
    const handleFilterChange = (filterName, value) => {
        setFilters(prev => ({ ...prev, [filterName]: value }));
        setCurrentPage(0);
    };

    // Handle search/clear
    const handleSearch = (e) => {
        e.preventDefault();
        fetchData();
    };

    const handleClear = () => {
        setFilters({
            palette: 'All',
            size: 'All',
            color_density: 'All',
            overlap: 'All',
            special_elements: 'All',
            traced: 'All',
            edge: 'All',
            flow: 'All',
            mint_type_palettes: 'All',
            sale_status: 'All',
            token_id: '',
            custom_profile: ''
        });
        setCurrentPage(0);
    };

    return (
        <div className="pebbles-main">
            <h1 className="pebbles-title">
                Pebbles by <a href="https://6529.io/zeblocks/rep" target="_blank" rel="noopener noreferrer">Zeblocks</a>
            </h1>

            {/* KPI Dashboard */}
            <div className="pebbles-dashboard">
                <div className="pebbles-kpi-box">
                    <h3>Market Cap (Bid Prices)</h3>
                    <p className="pebbles-kpi-value">{topBidMarketCap ? topBidMarketCap.toFixed(4) : 'N/A'} ETH</p>
                </div>
                <div className="pebbles-kpi-box">
                    <h3>No Of Pebbles For Sale</h3>
                    <p className="pebbles-kpi-value">{numberForSale} ({((numberForSale / 1000) * 100).toFixed(1)}%)</p>
                </div>
                <div className="pebbles-kpi-box">
                    <h3>Floor Price</h3>
                    <p className="pebbles-kpi-value">{floorPrice ? `${floorPrice.toFixed(4)} ETH` : 'N/A'}</p>
                </div>
                <div className="pebbles-kpi-box">
                    <h3>No Of Pebbles Never Sold</h3>
                    <p className="pebbles-kpi-value">{neverSoldCount} ({((neverSoldCount / 1000) * 100).toFixed(1)}%)</p>
                </div>
            </div>

            {/* Filters Form */}
            <form className="pebbles-filters-form" onSubmit={handleSearch}>
                {/* First row of filters */}
                <div className="pebbles-filters-row">
                    <div className="pebbles-filter-item">
                        <label>Token ID</label>
                        <input
                            type="text"
                            value={filters.token_id}
                            onChange={(e) => handleFilterChange('token_id', e.target.value)}
                            placeholder="Enter token ID..."
                        />
                    </div>
                    <div className="pebbles-filter-item">
                        <label>Palette</label>
                        <select
                            value={filters.palette}
                            onChange={(e) => handleFilterChange('palette', e.target.value)}
                        >
                            {filterOptions.palette.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-filter-item">
                        <label>Size</label>
                        <select
                            value={filters.size}
                            onChange={(e) => handleFilterChange('size', e.target.value)}
                        >
                            {filterOptions.size.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-filter-item">
                        <label>Colour Density</label>
                        <select
                            value={filters.color_density}
                            onChange={(e) => handleFilterChange('color_density', e.target.value)}
                        >
                            {filterOptions.color_density.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Second row of filters */}
                <div className="pebbles-filters-row">
                    <div className="pebbles-filter-item">
                        <label>Overlap</label>
                        <select
                            value={filters.overlap}
                            onChange={(e) => handleFilterChange('overlap', e.target.value)}
                        >
                            {filterOptions.overlap.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-filter-item">
                        <label>Special Elements</label>
                        <select
                            value={filters.special_elements}
                            onChange={(e) => handleFilterChange('special_elements', e.target.value)}
                        >
                            {filterOptions.special_elements.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-filter-item">
                        <label>Traced</label>
                        <select
                            value={filters.traced}
                            onChange={(e) => handleFilterChange('traced', e.target.value)}
                        >
                            {filterOptions.traced.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-filter-item">
                        <label>Edge</label>
                        <select
                            value={filters.edge}
                            onChange={(e) => handleFilterChange('edge', e.target.value)}
                        >
                            {filterOptions.edge.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Third row of filters */}
                <div className="pebbles-filters-row">
                    <div className="pebbles-filter-item">
                        <label>Flow</label>
                        <select
                            value={filters.flow}
                            onChange={(e) => handleFilterChange('flow', e.target.value)}
                        >
                            {filterOptions.flow.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-filter-item">
                        <label>Mint Type Palettes</label>
                        <select
                            value={filters.mint_type_palettes}
                            onChange={(e) => handleFilterChange('mint_type_palettes', e.target.value)}
                        >
                            {filterOptions.mint_type_palettes.map(value => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="pebbles-filter-item">
                        <label>Sale Status</label>
                        <select
                            value={filters.sale_status}
                            onChange={(e) => handleFilterChange('sale_status', e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="for_sale">Pebbles For Sale</option>
                            <option value="not_for_sale">Pebbles Not For Sale</option>
                        </select>
                    </div>
                    <div className="pebbles-filter-item">
                        <label>Owner/Wallet</label>
                        <input
                            type="text"
                            value={filters.custom_profile || ''}
                            onChange={(e) => handleFilterChange('custom_profile', e.target.value)}
                            placeholder="Enter profile name or wallet address..."
                            className="owner-search-input"
                        />
                    </div>
                </div>

                {/* Action buttons and record count */}
                <div className="pebbles-action-buttons-row">
                    <div className="pebbles-buttons-group">
                        <button type="submit">Search</button>
                        <button type="button" onClick={handleClear}>Clear</button>
                    </div>
                    <span className="pebbles-records-count">
                        <strong>Number of Records:</strong> {totalRecords}
                    </span>
                </div>
            </form>

            {/* Table */}
            <table className="pebbles-table">
                <thead>
                    <tr>
                        <th onClick={() => handleSort('token_id')}>
                            <div className="pebbles-header-content">
                                No
                                <ArrowUpDown className={`pebbles-sort-icon ${sortConfig.key === 'token_id' ? (sortConfig.direction === 'asc' ? 'pebbles-sorted-asc' : 'pebbles-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th>Name</th>
                        <th>Art</th>
                        <th onClick={() => handleSort('floor_ask_decimal')}>
                            <div className="pebbles-header-content">
                                Sale Price
                                <ArrowUpDown className={`pebbles-sort-icon ${sortConfig.key === 'floor_ask_decimal' ? (sortConfig.direction === 'asc' ? 'pebbles-sorted-asc' : 'pebbles-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => handleSort('top_bid_decimal')}>
                            <div className="pebbles-header-content">
                                Top Bid
                                <ArrowUpDown className={`pebbles-sort-icon ${sortConfig.key === 'top_bid_decimal' ? (sortConfig.direction === 'asc' ? 'pebbles-sorted-asc' : 'pebbles-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th>Last Sale</th>
                        <th onClick={() => handleSort('lastsale_timestamp')}>
                            <div className="pebbles-header-content">
                                Last Sale Date
                                <ArrowUpDown className={`pebbles-sort-icon ${sortConfig.key === 'lastsale_timestamp' ? (sortConfig.direction === 'asc' ? 'pebbles-sorted-asc' : 'pebbles-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th>Palette</th>
                        <th>Size</th>
                        <th>Colour Density</th>
                        <th>Overlap</th>
                        <th>Special Elements</th>
                        <th>Traced</th>
                        <th>Edge</th>
                        <th>Flow</th>
                        <th>Owner</th>
                        <th onClick={() => handleSort('tdh')}>
                            <div className="pebbles-header-content">
                                TDH
                                <ArrowUpDown className={`pebbles-sort-icon ${sortConfig.key === 'tdh' ? (sortConfig.direction === 'asc' ? 'pebbles-sorted-asc' : 'pebbles-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th onClick={() => handleSort('updated_at')}>
                            <div className="pebbles-header-content">
                                Last Update
                                <ArrowUpDown className={`pebbles-sort-icon ${sortConfig.key === 'updated_at' ? (sortConfig.direction === 'asc' ? 'pebbles-sorted-asc' : 'pebbles-sorted-desc') : ''}`} />
                            </div>
                        </th>
                        <th>6529</th>
                        <th>OS</th>
                        <th>Blur</th>
                    </tr>
                </thead>
                <tbody>
                    {pebblesData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.token_id}</td>
                            <td>{item.name}</td>
                            <td>
                                <img
                                    src={item.image_small}
                                    alt={item.name}
                                    className="pebbles-item-image"
                                    onClick={() => window.open(item.image_large, '_blank')}
                                />
                            </td>
                            <td>
                                {item.floor_ask_decimal ? (
                                    <>
                                        {parseFloat(item.floor_ask_decimal).toFixed(4)}
                                        {item.floor_source_icon && (
                                            <a href={item.floor_source_url} target="_blank" rel="noopener noreferrer">
                                                <img src={item.floor_source_icon} alt="Floor Source" className="pebbles-icon-image" />
                                            </a>
                                        )}
                                    </>
                                ) : 'Not Listed'}
                            </td>
                            <td>
                                {item.top_bid_decimal ? (
                                    <>
                                        {parseFloat(item.top_bid_decimal).toFixed(4)}
                                        {item.top_bid_source_icon && (
                                            <a href={item.top_bid_source_url} target="_blank" rel="noopener noreferrer">
                                                <img src={item.top_bid_source_icon} alt="Top Bid Source" className="pebbles-icon-image" />
                                            </a>
                                        )}
                                    </>
                                ) : 'N/A'}
                            </td>
                            <td>{item.lastsale_timestamp && item.lastsale_pricedecimal && 
                                !(parseFloat(item.lastsale_pricedecimal).toFixed(4) === '0.0653' && new Date(parseInt(item.lastsale_timestamp) * 1000).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) === '8 Feb 2024') 
                                ? parseFloat(item.lastsale_pricedecimal).toFixed(4) 
                                : 'Diamond Hand'}</td>
                            <td>{item.lastsale_timestamp && item.lastsale_pricedecimal && 
                                !(parseFloat(item.lastsale_pricedecimal).toFixed(4) === '0.0653' && new Date(parseInt(item.lastsale_timestamp) * 1000).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) === '8 Feb 2024') 
                                ? formatDate(item.lastsale_timestamp) 
                                : 'Diamond Hand'}</td>
                            <td>{item.attribute_palette}</td>
                            <td>{item.attribute_size}</td>
                            <td>{item.attribute_color_density}</td>
                            <td>{item.attribute_overlap}</td>
                            <td>{item.attribute_special_elements}</td>
                            <td>{item.attribute_traced}</td>
                            <td>{item.attribute_edge}</td>
                            <td>{item.attribute_flow}</td>
                            <td>
                                <a 
                                    href={`https://6529.io/${item.custom_profile}/identity`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="owner-link"
                                >
                                    {item.custom_profile && item.custom_profile.startsWith('0x') 
                                        ? `${item.custom_profile.substring(0, 6)}...${item.custom_profile.substring(item.custom_profile.length - 4)}`
                                        : item.custom_profile}
                                </a>
                            </td>
                            <td>{item.tdh}</td>
                            <td>{new Date(item.updated_at).toLocaleString()}</td>
                            <td>
                                <a href={`https://6529.io/nextgen/token/${item.token_id}`} target="_blank" rel="noopener noreferrer">
                                    <img src={seizeIcon} alt="6529" className="pebbles-icon-image" />
                                </a>
                            </td>
                            <td>
                                <a href={`https://opensea.io/assets/ethereum/${item.contract}/${item.token_id}`} target="_blank" rel="noopener noreferrer">
                                    <img src={openseaIcon} alt="OpenSea" className="pebbles-icon-image" />
                                </a>
                            </td>
                            <td>
                                <a href={`https://blur.io/eth/asset/${item.contract}/${item.token_id}`} target="_blank" rel="noopener noreferrer">
                                    <img src={blurIcon} alt="Blur" className="pebbles-icon-image" />
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            <ReactPaginate
                previousLabel={'← Previous'}
                nextLabel={'Next →'}
                breakLabel={'...'}
                pageCount={Math.ceil(totalRecords / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pebbles-pagination'}
                activeClassName={'active'}
            />
        </div>
    );
}

export default PebblesMainComponent; 