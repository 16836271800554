// TokenOwnersComponent.js

import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchCommunityMembersData } from './apiService';
import './TokenOwnersComponent.css';

// Import icons (ensure these paths are correct based on your project structure)
import openseaIcon from './assets/openseaIcon.png';
import seizeIcon from './assets/seizeIcon.png';

const TokenOwnersComponent = () => {
    const { tokenid } = useParams(); // Extract tokenid from URL parameters
    const [tokenData, setTokenData] = useState(null); // Store token details
    const [holdersData, setHoldersData] = useState([]); // Store all holders
    const [loading, setLoading] = useState(true); // Loading state
    const [searchTerm, setSearchTerm] = useState(''); // Address search term
    const [profileSearchTerm, setProfileSearchTerm] = useState(''); // Profile search term
    const [showSuggestions, setShowSuggestions] = useState(false); // Toggle suggestions
    const [filteredHolders, setFilteredHolders] = useState([]); // Filtered holders based on search
    const [uniqueAddresses, setUniqueAddresses] = useState([]); // Unique addresses for suggestions

    // New state for filters
    const [activeFilters, setActiveFilters] = useState({
        ecosystem6529: false,
        fullSetHolders: false,
        sznSetHolders: false,
        others: false
    });

    // Move group1Keys into useMemo
    const group1Keys = useMemo(() => new Set([
        // Set 1 - Punk6529 ecosystem addresses
        '0x6daa633c23615a29471deafae351727867e7dad1-0xa094ca13e855c3e926f9069ee140602ace421fe3-0xfd22004806a6846ea67ad883356be810f0428793',
        '0x32b8bce7cf8c6203a841b0d08bde5ae89f0af103-0xc2ce4ccef11a8171f443745cea3bceeaadd750c7',
        '0x924817154d311bd2de599f3d659d053ab0133e06-0xc6400a5584db71e41b0e5dfbdc769b54b91256cd',
        // Set 2 - SZN Funds addresses
        '0x8ba68cfe71550efc8988d81d040473709b7f9218-0xe227d0100de9b44a98acdc5ba1ecb7d12188a290',
        '0x1cc42f89b5c13d5a4eec2c151d8037b6597d73fc-0xa743c8c57c425b84cb2ed18c6b9ae3ad21629cb5',
        // Set 3 - 6529er addresses
        '0x7ba3e8f17582462676c44dd143388ed4b6b20655-0xde358686e7ae1973cf6a8c7ebf6ba00acb9d3a9d-0xe359ab04cec41ac8c62bc5016c10c749c7de5480'
    ].map(key => key.toLowerCase())), []); // Empty dependency array since these addresses never change

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchCommunityMembersData(tokenid);
                console.log('Fetched data:', data); // Debugging: Log fetched data

                const { token_id, prices, holders } = data;

                // Set token details
                setTokenData({
                    tokenid: token_id,
                    name: prices.Name,
                    TypeSeason: prices.TypeSeason,
                    Supply: prices.Supply,
                    Artist: prices.Artist,
                });

                // Sort holders by balance descending
                const sortedHolders = [...holders].sort((a, b) => b.balance - a.balance);
                setHoldersData(sortedHolders);
                setFilteredHolders(sortedHolders); // Initialize filtered data

                // Extract unique addresses for suggestions
                const addressesSet = new Set();
                holders.forEach(owner => {
                    owner.consolidation_key.split('-').forEach(address => {
                        addressesSet.add(address.toLowerCase());
                    });
                });
                setUniqueAddresses(Array.from(addressesSet));

                setLoading(false); // Data fetched successfully
            } catch (error) {
                console.error('Error fetching community members data:', error);
                setLoading(false); // Stop loading on error
            }
        };

        // Reset state when tokenid changes to prevent data overlap
        setTokenData(null);
        setHoldersData([]);
        setFilteredHolders([]);
        setUniqueAddresses([]);
        setLoading(true);

        fetchData(); // Fetch data for the current tokenid
    }, [tokenid]);

    // Enhanced filtering logic
    useEffect(() => {
        let filtered = holdersData;

        // Apply category filters if any are active
        if (Object.values(activeFilters).some(filter => filter)) {
            // Initialize holder sets for each category
            let group2KeysSet = new Set(); // Full Set Holders
            let group3KeysSet = new Set(); // SZN Set Holders
            let group4KeysSet = new Set(); // Other Holders

            // First pass: categorize all holders
            holdersData.forEach(owner => {
                const consolidationKey = owner.consolidation_key.toLowerCase();
                const overallNumFullSets = owner.overall_num_full_sets || 0;
                const sznNumFullSets = owner[`szn${tokenData?.TypeSeason}_num_full_sets`] || 0;

                if (group1Keys.has(consolidationKey)) {
                    // Group 1: 6529 Ecosystem
                    return;
                } else if (overallNumFullSets >= 1) {
                    // Group 2: Full Set Holders
                    group2KeysSet.add(consolidationKey);
                } else if (sznNumFullSets >= 1) {
                    // Group 3: SZN Set Holders
                    group3KeysSet.add(consolidationKey);
                } else {
                    // Group 4: Others
                    group4KeysSet.add(consolidationKey);
                }
            });

            // Second pass: apply filters
            filtered = holdersData.filter(owner => {
                const consolidationKey = owner.consolidation_key.toLowerCase();
                
                if (activeFilters.ecosystem6529 && group1Keys.has(consolidationKey)) {
                    return true;
                }
                if (activeFilters.fullSetHolders && group2KeysSet.has(consolidationKey)) {
                    return true;
                }
                if (activeFilters.sznSetHolders && group3KeysSet.has(consolidationKey)) {
                    return true;
                }
                if (activeFilters.others && group4KeysSet.has(consolidationKey)) {
                    return true;
                }
                return false;
            });
        }

        // Apply text search filters
        if (searchTerm.trim() !== '' || profileSearchTerm.trim() !== '') {
            filtered = filtered.filter(owner => {
                const addressMatch = owner.consolidation_key.toLowerCase().includes(searchTerm.toLowerCase());
                const profileMatch = profileSearchTerm.trim() === '' || 
                    (owner.display && owner.display.toLowerCase().includes(profileSearchTerm.toLowerCase()));
                return addressMatch && profileMatch;
            });
        }

        setFilteredHolders(filtered);
    }, [searchTerm, profileSearchTerm, holdersData, activeFilters, tokenData, group1Keys]);

    // Generate suggestions based on searchTerm
    const suggestions = useMemo(() => {
        if (searchTerm.length < 2) return [];
        return uniqueAddresses.filter(address =>
            address.includes(searchTerm.toLowerCase())
        ).slice(0, 5);
    }, [uniqueAddresses, searchTerm]);

    // Utility function to shorten addresses for display
    const shortenAddress = (address) => {
        if (address.length > 10) {
            return address.slice(0, 6) + '...' + address.slice(-4);
        }
        return address;
    };

    if (loading) {
        return <div className="spinner"></div>; // Show spinner while loading
    }

    if (!tokenData) {
        return <div>No data available for token {tokenid}.</div>; // Handle no data scenario
    }

    return (
        <div className="owners-container">
            {/* Header Section */}
            <div className="token-header">
                <h1>
                    {`6529 Meme Card ${tokenData.tokenid}. ${tokenData.name}`}
                    {/* OpenSea Icon */}
                    <a
                        href={`https://opensea.io/assets/ethereum/0x33fd426905f149f8376e227d0c9d3340aad17af1/${tokenData.tokenid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={openseaIcon} alt="OpenSea" className="marketplace-icon" />
                    </a>
                    {/* Seize Icon */}
                    <a
                        href={`https://seize.io/the-memes/${tokenData.tokenid}?focus=live`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={seizeIcon} alt="Seize" className="marketplace-icon" />
                    </a>
                </h1>
                <p>
                    {`Card No: ${tokenData.tokenid} | Season: ${tokenData.TypeSeason} | Supply: ${tokenData.Supply} | Artist: ${tokenData.Artist} | `}
                    <Link to={`/token/${tokenid}/owners`} className="owners-link"><strong>Owners: {holdersData.length}</strong></Link>
                </p>
            </div>

            {/* Filter Explainer */}
            <div className="filter-explainer">
                <p>
                    <strong>Understanding the filters:</strong> The filters work hierarchically, where holders may belong to multiple categories.
                    6529 Ecosystem holders may also be Full Set or SZN Set holders. Full Set holders may also be SZN Set holders.
                    To see all holders in a category, you should select that category plus all categories above it.
                </p>
            </div>

            {/* Filter Buttons Section */}
            <div className="filter-buttons">
                <button
                    className={`filter-btn ${activeFilters.ecosystem6529 ? 'active' : ''}`}
                    onClick={() => setActiveFilters(prev => ({
                        ...prev,
                        ecosystem6529: !prev.ecosystem6529
                    }))}
                >
                    6529 Ecosystem
                </button>
                <button
                    className={`filter-btn ${activeFilters.fullSetHolders ? 'active' : ''}`}
                    onClick={() => setActiveFilters(prev => ({
                        ...prev,
                        fullSetHolders: !prev.fullSetHolders
                    }))}
                >
                    Full Set Holders
                </button>
                <button
                    className={`filter-btn ${activeFilters.sznSetHolders ? 'active' : ''}`}
                    onClick={() => setActiveFilters(prev => ({
                        ...prev,
                        sznSetHolders: !prev.sznSetHolders
                    }))}
                >
                    SZN Set Holders
                </button>
                <button
                    className={`filter-btn ${activeFilters.others ? 'active' : ''}`}
                    onClick={() => setActiveFilters(prev => ({
                        ...prev,
                        others: !prev.others
                    }))}
                >
                    Others
                </button>
            </div>
            {/* Search Boxes */}
            <div className="search-container">
                {/* Search Box for Addresses */}
                <div className="search-box">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setShowSuggestions(true);
                        }}
                        onFocus={() => setShowSuggestions(true)}
                        onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                        placeholder="Search addresses..."
                        className="search-input"
                    />
                    {showSuggestions && suggestions.length > 0 && (
                        <ul className="suggestions-list">
                            {suggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onMouseDown={() => {
                                        setSearchTerm(suggestion);
                                        setShowSuggestions(false);
                                    }}
                                >
                                    {shortenAddress(suggestion)}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                {/* Search Box for Profiles */}
                <div className="search-box">
                    <input
                        type="text"
                        value={profileSearchTerm}
                        onChange={(e) => setProfileSearchTerm(e.target.value)}
                        placeholder="Search profiles..."
                        className="search-input"
                    />
                </div>
            </div>
            {/* Owners Table */}
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Holders</th>
                        <th>6529 Profile</th>
                        <th>Card {tokenid} Owned</th>
                        <th>Memes Balance</th>
                        <th>Unique Memes</th>
                        <th>Genesis Sets</th>
                        <th>Owns Nakamoto</th>
                        <th>Full Sets</th>
                        <th>TDH</th>
                        <th>Level</th>
                        <th>Rep</th>
                        <th>NIC</th>
                        <th>Last Active</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredHolders.map((owner, index) => {
                        const firstAddress = owner.consolidation_key.split('-')[0].toLowerCase();
                        const cleanedFirstAddress = firstAddress.replace(/^id-/, ''); // Remove 'id-' prefix if present
                        const displayName = owner.display ? owner.display : cleanedFirstAddress;
                        const profileUrl = `https://6529.io/${owner.display ? owner.display : firstAddress}/identity`;

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{owner.consolidation_key}</td>
                                <td>
                                    <a href={profileUrl} target="_blank" rel="noopener noreferrer" className="profile-link">
                                        {displayName}
                                    </a>
                                </td>
                                <td>{owner.balance !== undefined && owner.balance !== null ? owner.balance : 'N/A'}</td>
                                <td>{owner.memes_balance !== undefined && owner.memes_balance !== null ? owner.memes_balance : 'N/A'}</td>
                                <td>{owner.unique_memes !== undefined && owner.unique_memes !== null ? owner.unique_memes : 'N/A'}</td>
                                <td>{owner.genesis !== undefined && owner.genesis !== null ? owner.genesis : 'N/A'}</td>
                                <td>{owner.nakamoto !== undefined && owner.nakamoto !== null ? owner.nakamoto : 'N/A'}</td>
                                <td>{owner.memes_cards_sets !== undefined && owner.memes_cards_sets !== null ? owner.memes_cards_sets : 'N/A'}</td>
                                <td>{owner.tdh !== undefined && owner.tdh !== null ? owner.tdh : 'N/A'}</td>
                                <td>{owner.level !== undefined && owner.level !== null ? owner.level : 'N/A'}</td>
                                <td>{owner.rep !== undefined && owner.rep !== null ? owner.rep : 'N/A'}</td>
                                <td>{owner.cic !== undefined && owner.cic !== null ? owner.cic : 'N/A'}</td>
                                <td>{owner.last_activity ? new Date(owner.last_activity).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) : 'N/A'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

};

export default TokenOwnersComponent;
