// TdhTableComponent.js

import React, { useEffect, useState, useMemo } from 'react';
import { fetchPrices } from './apiService';
import './TdhTableComponent.css'; // Import the component-specific CSS
import { ArrowUpDown, Info } from 'lucide-react'; // Import the Info icon
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom'; // Import NavLink for routing

const TdhTableComponent = () => {
  const [prices, setPrices] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'ID', direction: 'ascending' });
  const [latestTimestamp, setLatestTimestamp] = useState('N/A');
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [selectedMemeName, setSelectedMemeName] = useState('All');
  const [selectedSupply, setSelectedSupply] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const itemsPerPage = 25; // Adjust as needed

  useEffect(() => {
    const getPrices = async () => {
      try {
        const response = await fetchPrices({
          page: currentPage + 1,
          pageSize: itemsPerPage,
          sortKey: sortConfig.key,
          sortDirection: sortConfig.direction === 'ascending' ? 'asc' : 'desc',
          szn: selectedSZN,
          memeName: selectedMemeName,
          supply: selectedSupply,
          searchTerm: searchTerm,
        });
        console.log('API Response:', response.data);
        setPrices(response.data);
        setTotalRecords(response.totalCount);

        if (response.data.length > 0) {
          const latestTime = new Date(Math.max(...response.data.map(item => new Date(item.Timestamp))));

          const ukTime = latestTime.toLocaleString('en-GB', {
            timeZone: 'Europe/London',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          });
          const utcTime = latestTime.toUTCString().split(' ').slice(1, 5).join(' ');

          setLatestTimestamp(`UK Time: ${ukTime}, UTC: ${utcTime}`);
        }
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };

    getPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    sortConfig,
    selectedSZN,
    selectedMemeName,
    selectedSupply,
    searchTerm,
  ]);

  const sortTable = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(0); // Reset to first page when sorting changes
  };

  // Handle page change
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const suggestions = useMemo(() => {
    if (searchTerm.length < 2) return [];
    const uniqueNames = new Set(prices.map(item => item.Name));
    return Array.from(uniqueNames).filter(name =>
      name.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(0, 5);
  }, [prices, searchTerm]);

  // Unique values for filters
  const [uniqueSZNs, setUniqueSZNs] = useState(['All']);
  const [uniqueMemeNames, setUniqueMemeNames] = useState(['All']);
  const [uniqueSupplies, setUniqueSupplies] = useState(['All']);

  useEffect(() => {
    // Fetch unique SZNs, MemeNames, and Supplies
    const fetchUniqueValues = async () => {
      try {
        const response = await fetchPrices({
          page: 1,
          pageSize: 1000, // Fetch all records to get unique values
        });
        const data = response.data;
        const szns = new Set(data.map(item => item.TypeSeason));
        setUniqueSZNs(['All', ...Array.from(szns).filter(szn => szn !== null && szn !== '')]);
        const memeNames = new Set(data.map(item => item.MemeName));
        setUniqueMemeNames(['All', ...Array.from(memeNames).filter(name => name !== null && name !== '')]);
        const supplies = new Set(data.map(item => item.Supply));
        setUniqueSupplies(['All', ...Array.from(supplies).sort((a, b) => a - b)]);
      } catch (error) {
        console.error('Error fetching unique values:', error);
      }
    };
    fetchUniqueValues();
  }, []);

  return (
    <div className="tdh-table-container">
      <h1 className="main-header">TDH / PPTDH Stats</h1>
      <h2 className="sub-header">Current TDH and PPTDH Values</h2>
      <div className="last-updated">
        <strong>Last Updated: </strong> {latestTimestamp}
      </div>

      <div className="filter-container">
        <div>
          <label htmlFor="szn-filter">SZN: </label>
          <select
            id="szn-filter"
            value={selectedSZN}
            onChange={(e) => {
              setSelectedSZN(e.target.value);
              setCurrentPage(0);
            }}
          >
            {uniqueSZNs.map((szn) => (
              <option key={szn} value={szn}>{szn}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="meme-name-filter">Meme Name: </label>
          <select
            id="meme-name-filter"
            value={selectedMemeName}
            onChange={(e) => {
              setSelectedMemeName(e.target.value);
              setCurrentPage(0);
            }}
          >
            {uniqueMemeNames.map((memeName) => (
              <option key={memeName} value={memeName}>{memeName}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="supply-filter">Supply: </label>
          <select
            id="supply-filter"
            value={selectedSupply}
            onChange={(e) => {
              setSelectedSupply(e.target.value);
              setCurrentPage(0);
            }}
          >
            {uniqueSupplies.map((supply) => (
              <option key={supply} value={supply}>{supply}</option>
            ))}
          </select>
        </div>
        <div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setShowSuggestions(true);
              setCurrentPage(0);
            }}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
            placeholder="Search cards..."
          />
          {showSuggestions && suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onMouseDown={() => {
                    setSearchTerm(suggestion);
                    setShowSuggestions(false);
                    setCurrentPage(0);
                  }}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="table-wrapper">
        <table className="centered-table">
          <thead>
            <tr>
              <th onClick={() => sortTable('ID')}>
                Token No <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('Name')}>
                Name <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('MemeName')}>
                Meme Name <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('Supply')}>
                Supply <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('TypeSeason')}>
                SZN <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('FloorPrice')}>
                Ask Price <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('HighestBid')}>
                Top Bid <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('LastSalePrice')}>
                Last Sale Price <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('HodlRate')}>
                <span className="tooltip">
                  TDH Rate<span style={{ color: 'red' }}>*</span>
                  <span className="tooltiptext">Calculated as 3941 / Supply</span>
                </span>
                <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('PPTDH')}>
                <span className="tooltip">
                  PPTDH<span style={{ color: 'red' }}>*</span>
                  <span className="tooltiptext">Calculated as Ask Price / TDH Rate</span>
                </span>
                <ArrowUpDown className="inline" />
              </th>
              {/* New Info Column */}
              <th>Info</th>
              <th onClick={() => sortTable('Timestamp')}>
                Last Updated <ArrowUpDown className="inline" />
              </th>
            </tr>
          </thead>
          <tbody>
            {prices.map((price, index) => (
              <tr key={index}>
                <td>{price.ID}</td>
                <td>
                  <NavLink
                    to={`/token/${price.ID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="name-link"
                  >
                    {price.Name} <Info className="inline-icon" />
                  </NavLink>
                </td>
                <td>{price.MemeName}</td>
                <td>{price.Supply}</td>
                <td>{price.TypeSeason}</td>
                <td>{price.FloorPrice}</td>
                <td>{price.HighestBid ? parseFloat(price.HighestBid).toFixed(5) : 'N/A'}</td>
                <td>{price.LastSalePrice ? parseFloat(price.LastSalePrice).toFixed(5) : 'N/A'}</td>
                <td>{price.HodlRate}</td>
                <td>{price.PPTDH}</td>
                {/* New Info Column */}
                <td>
                  <NavLink
                    to={`/token/${price.ID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Info className="info-icon" />
                  </NavLink>
                </td>
                <td>{new Date(price.Timestamp).toLocaleString('en-GB', {
                  timeZone: 'Europe/London',
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false,
                })}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="pagination">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          pageCount={Math.ceil(totalRecords / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    </div>
  );
};

export default TdhTableComponent;
