import React from 'react';
import './NFTCollectionsComponent.css';
import SubstackMint from './assets/SubstackMint.jpg';
import HoldersMint from './assets/HoldersMint.png';

const NFTCollectionsComponent = () => {
    return (
        <div className="nft-collections-container">
            <h1>NFT Collections</h1>
            
            <div className="nft-content">
                <p>I aim to offer a couple of NFT collections centered around the 6529 network, primarily focused on charting and recording its growth and progress over the months and years ahead.</p>
                
                <div className="purposes-section">
                    <p>These collections have two main purposes:</p>
                    <ul>
                        <li>To provide affordable NFT collectibles that document the journey of 6529 Memes and other 6529 projects.</li>
                        <li>To offer a crypto-native way to support me in developing and maintaining free resources like this website and the weekly Substack newsletter.</li>
                    </ul>
                </div>

                <div className="collection-section">
                    <h2>6529 Memes & More Substack Collection</h2>
                    <div className="collection-details">
                        <p>The first collection features NFTs for each issue of the 6529 Memes & More by Vantekai newsletter. Issues are generally released weekly, though occasional extras may be added.</p>
                        
                        <p>All mints are open editions on Base with no end date and a mint price of 0.0006529 ETH (plus the standard Manifold fee). As of today, the total cost per mint is around $3.50.</p>
                        
                        <p>No FOMO, no speculation—just a simple way to collect and support.</p>
                        
                        <div className="collection-image">
                            <a href="https://manifold.gallery/6529byvantekai/curation/all" target="_blank" rel="noopener noreferrer">
                                <img src={SubstackMint} alt="Substack Mint Collection" />
                            </a>
                        </div>

                        <div className="marketplace-links">
                            <p>All editions are available to <a href="https://manifold.gallery/6529byvantekai/curation/all" target="_blank" rel="noopener noreferrer">Mint on Manifold</a></p>
                            
                            <p>View the collection on <a href="https://opensea.io/collection/6529-memes-more-news" target="_blank" rel="noopener noreferrer">Opensea</a> and on <a href="https://magiceden.io/collections/base/0xd922d174f68270f0254593cedd612a1e5083c0fd" target="_blank" rel="noopener noreferrer">Magic Eden</a></p>
                            
                            <p>View the Newsletter on <a href="https://vantekai.substack.com/" target="_blank" rel="noopener noreferrer">Substack</a></p>
                        </div>
                    </div>
                </div>

                <div className="collection-section">
                    <h2>6529 Memes Holders Count & Tracking Collection</h2>
                    <div className="collection-details">
                        <p>The second collection features NFTs that record the 6529 Memes Holders count on a weekly basis as well as recording the weekly movement of the number of holders. A NFT will be releases once a week usually on a Saturday</p>
                        
                        <p>All mints are open editions on Base with no end date and a mint price of 0.0006529 ETH (plus the standard Manifold fee). As of today, the total cost per mint is around $3.50.</p>
                        
                        <p>No FOMO, no speculation—just a simple way to collect and support.</p>
                        
                        <div className="collection-image">
                            <a href="https://manifold.gallery/6529byvantekai/curation/all-dates" target="_blank" rel="noopener noreferrer">
                                <img src={HoldersMint} alt="Holders Mint Collection" />
                            </a>
                        </div>

                        <div className="marketplace-links">
                            <p>All editions are available to <a href="https://manifold.gallery/6529byvantekai/curation/all-dates" target="_blank" rel="noopener noreferrer">Mint on Manifold</a></p>
                            
                            <p>View the collection on <a href="https://opensea.io/collection/6529-memes-more-holders-count" target="_blank" rel="noopener noreferrer">Opensea</a> and on <a href="https://magiceden.io/collections/base/0x7bdd1a71a851c53a13f31cf5cc4c100f22d3bdeb" target="_blank" rel="noopener noreferrer">Magic Eden</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTCollectionsComponent; 