import React from 'react';
import './ServicesComponent.css';

const ServicesComponent = () => {
  return (
    <div className="services-container">
      <h1><span className="accent-text">Supporting</span> 6529 Stats</h1>
      
      <div className="services-intro">
        <p>6529 Stats is a free resource dedicated to providing comprehensive analytics and insights for the 6529 Memes and NextGen NFT collections. The site will always remain free, but there are several premium services available that can enhance your experience while helping support the ongoing development and maintenance of the site.</p>
      </div>

      <h2 className="services-title accent-text">Our Services</h2>

      <div className="service-card">
        <h3>Email Notifications</h3>
        <div className="service-content">
          <p>Stay informed with customizable email alerts for metrics that matter to you. Whether you're tracking prices, monitoring sales, or keeping tabs on your portfolio, our notification system adapts to your needs:</p>
          <ul>
            <li>Flexible timing: From minutes to weekly updates</li>
            <li>Customizable thresholds to prevent notification fatigue</li>
            <li>Wide range of trackable metrics</li>
          </ul>
          <a href="https://6529stats.xyz/notifications" className="service-link">Example Notifications</a>
        </div>
      </div>

      <div className="service-card">
        <h3>Data Export Services</h3>
        <div className="service-content">
          <p>Access and analyze our data your way:</p>
          <ul>
            <li>Regular CSV exports delivered to your inbox</li>
            <li>Shared drive links for easy access</li>
            <li>Comprehensive datasets covering all site metrics</li>
          </ul>
          <p className="pricing-note">Pricing available on request based on requirements</p>
        </div>
      </div>

      <div className="service-card">
        <h3>Artist Custom Profiles</h3>
        <div className="service-content">
          <p>For 6529 Meme Card artists, we offer bespoke profile pages to showcase your broader portfolio:</p>
          <ul>
            <li>Link to your social and professional profiles (LinkedIn, Instagram, etc.)</li>
            <li>Display your wider art collection</li>
            <li>Highlight your achievements and background</li>
            <li>Cost: 1 Meme Card from your artist allocation</li>
          </ul>
          <a href="https://6529stats.xyz/about/artists" className="service-link">View Artist Page</a>
        </div>
      </div>

      <div className="service-card">
        <h3>Custom Collection Analytics</h3>
        <div className="service-content">
          <p>Our detailed collector analytics currently cover the 6529 ecosystem, MFERS, and REKTGUY. We offer:</p>
          <ul>
            <li>Integration of additional external collections</li>
            <li>Detailed per-NFT analysis</li>
            <li>Comprehensive aggregated data views</li>
          </ul>
          <p className="pricing-note">Pricing available on request based on requirements</p>
        </div>
      </div>

      <div className="service-card">
        <h3>NFT Collections</h3>
        <div className="service-content">
          <p>The first NFT collection available to mint is one per issue of the 6529 Memes & More Newsletter</p>
          
          <p>This like future collections will have two main purposes:</p>
          <ul>
            <li>To provide affordable NFT collectibles that document the journey of 6529 Memes and other 6529 projects.</li>
            <li>To offer a crypto-native way to support me in developing and maintaining free resources like this website and the weekly Substack newsletter.</li>
          </ul>
          
          <p>All mints are open editions on Base with no end date and a mint price of 0.0006529 ETH (plus the standard Manifold fee). As of today, the total cost per mint is around $3.50.</p>
          
          <p>No FOMO, no speculation—just a simple way to collect and support.</p>
          
          <div className="nft-links">
            <p><a href="https://6529stats.xyz/nft-collections" target="_blank" rel="noopener noreferrer">View the NFT Collection Page</a></p>
          </div>
        </div>
      </div>

      <div className="service-card">
        <h3>Feature Request</h3>
        <div className="service-content">
          <p>I'm always working on adding new features and pages to the site, while also tweaking and improving what's already here</p>
          <p>If there's a specific feature you'd like to see, feel free to reach out. I'll look into whether it's possible and provide you with a quote</p>
        </div>
      </div>

      <div className="support-section">
        <h3>Support The Site</h3>
        <p>If you find the site valuable, then tips are welcome at <strong>6529stats.eth</strong> to help maintain and improve the platform.</p>
      </div>

      <div className="contact-button-container">
        <a href="https://6529stats.xyz/contact" className="contact-button">Contact</a>
      </div>
    </div>
  );
};

export default ServicesComponent; 