// MyPortfolioComponent.js

import React, { useState, useMemo, useCallback } from 'react';
import { ArrowUpDown, Info } from 'lucide-react';
import './MyPortfolioComponent.css';
import { NavLink } from 'react-router-dom';
import { fetchPortfolioAllData, fetchPortfolioPebblesData } from './apiService';
import ReactPaginate from 'react-paginate';
import seizeIcon from './assets/seizeIcon.png';
import openseaIcon from './assets/openseaIcon.png';
import blurIcon from './assets/blurIcon.png';

const MyPortfolioComponent = () => {
  // State variables
  const [profile, setProfile] = useState('');
  const [portfolioData, setPortfolioData] = useState([]);
  const [collectionValuation, setCollectionValuation] = useState({
    bid: 0,
    ask: 0,
    lastSale: 0,
    withDepth: 0,
  });
  const [sznValuations, setSznValuations] = useState([]);
  const [sznCostToCompleteFloor, setSznCostToCompleteFloor] = useState([]);
  const [completeCollectionCostToCompleteFloor, setCompleteCollectionCostToCompleteFloor] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [ownershipFilter, setOwnershipFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;
  const [pebblesData, setPebblesData] = useState([]);
  const [pebblesCurrentPage, setPebblesCurrentPage] = useState(0);
  const [portfolioFilter, setPortfolioFilter] = useState('all');

  /**
   * Handles form submission to fetch portfolio data.
   */
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const [portfolioResponse, pebblesResponse] = await Promise.all([
        fetchPortfolioAllData(profile),
        fetchPortfolioPebblesData(profile)
      ]);

      setPortfolioData(portfolioResponse.data);
      setPebblesData(pebblesResponse.data || []);
      
      setCollectionValuation({
        bid: portfolioResponse.collectionValuationBid,
        ask: portfolioResponse.collectionValuationAsk,
        lastSale: portfolioResponse.collectionValuationLastSale,
        withDepth: portfolioResponse.collectionValuationWithDepth,
      });

      // Extract SZN Valuations and Costs
      const sznData = portfolioResponse.sznValuations || [];
      const sznCostData = portfolioResponse.sznCostToCompleteFloor || [];

      setSznValuations(sznData);
      setSznCostToCompleteFloor(sznCostData);
      setCompleteCollectionCostToCompleteFloor(portfolioResponse.CompleteCollectionCostToCompleteFloor || 0);
      setLastUpdated(new Date(portfolioResponse.lastUpdated));
    } catch (err) {
      setError('Error fetching portfolio data. Please try again.');
      console.error('Error details:', err);
    }
    setLoading(false);
  }, [profile]);

  /**
   * Formats numbers to a fixed number of decimal places with thousand separators.
   */
  const formatNumber = (num) => {
    if (typeof num !== 'number') {
      num = parseFloat(num);
    }
    return num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  /**
   * Formats the date to display both UK and UTC times.
   */
  const formatDate = (date) => {
    const ukTime = date.toLocaleString('en-GB', {
      timeZone: 'Europe/London',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const utcTime = date.toLocaleString('en-GB', {
      timeZone: 'UTC',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return `UK Time: ${ukTime}, UTC: ${utcTime}`;
  };

  /**
   * Handles sorting of table columns.
   */
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  /**
   * Memoized sorted data based on sort configuration.
   */
  const sortedData = useMemo(() => {
    let sortableItems = [...portfolioData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        const isNumericField = [
          'ID',
          'HighestBid',
          'FloorPrice',
          'LastSalePrice',
          'PPTDH',
          'SeizedCount',
          'BidValuation',
          'ValuationWithDepth',
          'AskValuation',
          'TypeSeason',
        ].includes(sortConfig.key);

        if (isNumericField) {
          const aNum = parseFloat(aValue) || 0;
          const bNum = parseFloat(bValue) || 0;
          return sortConfig.direction === 'ascending' ? aNum - bNum : bNum - aNum;
        } else {
          if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableItems;
  }, [portfolioData, sortConfig]);

  /**
   * Memoized filtered data based on selected filters and search term.
   */
  const filteredData = useMemo(() => {
    return sortedData.filter(item =>
      (selectedSZN === 'All' || item.TypeSeason === selectedSZN) &&
      (searchTerm === '' || item.Name.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (ownershipFilter === 'all' ||
        (ownershipFilter === 'owned' && item.SeizedCount > 0) ||
        (ownershipFilter === 'notowned' && item.SeizedCount === 0))
    );
  }, [sortedData, selectedSZN, searchTerm, ownershipFilter]);

  /**
   * Extracts unique SZNs from the portfolio data for filter options.
   */
  const uniqueSZNs = useMemo(() => {
    const szns = new Set(portfolioData.map(item => item.TypeSeason));
    return ['All', ...Array.from(szns).sort((a, b) => a - b)];
  }, [portfolioData]);

  // Handle page change
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Memoized filtered and paginated data
  const paginatedData = useMemo(() => {
    const filteredItems = filteredData;
    const startIndex = currentPage * itemsPerPage;
    return filteredItems.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredData, currentPage, itemsPerPage]);

  // Filtered Pebbles data
  const filteredPebblesData = useMemo(() => {
    return pebblesData;
  }, [pebblesData]);

  // Paginated Pebbles data
  const paginatedPebblesData = useMemo(() => {
    const startIndex = pebblesCurrentPage * itemsPerPage;
    return filteredPebblesData.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredPebblesData, pebblesCurrentPage, itemsPerPage]);

  // Handle Pebbles page change
  const handlePebblesPageClick = (selectedPage) => {
    setPebblesCurrentPage(selectedPage.selected);
  };

  return (
    <div className="my-portfolio-container">
      {/* Header and Form */}
      <h1 className="my-portfolio-page-header">My 6529 Memes Portfolio</h1>
      <div className="my-portfolio-form-container">
        <form onSubmit={handleSubmit} className="my-portfolio-form">
          <input
            type="text"
            value={profile}
            onChange={(e) => setProfile(e.target.value)}
            placeholder="Enter your Seize profile"
            required
          />
          <button type="submit">
            Fetch Seize Portfolio
          </button>
        </form>
        <p className="my-portfolio-description">
          Enter your Seize profile or any wallet address within your consolidation to receive your current Bid &amp; Ask price valuations for your 6529 Memes portfolio
        </p>
      </div>

      {/* Loading and Error Messages */}
      {loading && <p className="my-portfolio-loading-message">Loading...</p>}
      {error && <p className="my-portfolio-error-message">{error}</p>}

      {/* Portfolio Valuation Table */}
      {portfolioData.length > 0 && (
        <>
          <h2 className="my-portfolio-sub-header">My Portfolio</h2>

          <div className="my-portfolio-complete-portfolio-data">
            {/* Portfolio Value Filter */}
            <div className="my-portfolio-filter-container">
              <div>
                <label htmlFor="portfolio-filter">Portfolio Value: </label>
                <select
                  id="portfolio-filter"
                  value={portfolioFilter}
                  onChange={(e) => setPortfolioFilter(e.target.value)}
                >
                  <option value="all">All Collections</option>
                  <option value="memes">6529 Memes Only</option>
                  <option value="pebbles">Pebbles Only</option>
                </select>
              </div>
            </div>

            <table className="my-portfolio-styled-table">
              <thead>
                <tr>
                  <th>Season</th>
                  <th>Valuation (Bid Prices)</th>
                  <th>Valuation (Bids Price With Depth)</th>
                  <th>Valuation (Ask Prices)</th>
                  <th>Valuation (Last Sale)</th>
                  <th>Cost to Complete (Ask Prices)</th>
                </tr>
              </thead>
              <tbody>
                {portfolioFilter !== 'pebbles' && sznValuations.map((szn) => {
                  const costData = sznCostToCompleteFloor.find(item => item.szn === szn.szn);
                  return (
                    <tr key={szn.szn}>
                      <td>SZN {szn.szn}</td>
                      <td>{formatNumber(szn.bid)}</td>
                      <td>{formatNumber(szn.bidWithDepth || 0)}</td>
                      <td>{formatNumber(szn.ask)}</td>
                      <td>{formatNumber(szn.lastSale)}</td>
                      <td>{formatNumber(costData ? costData.costToComplete : 0)}</td>
                    </tr>
                  );
                })}
                {portfolioFilter !== 'memes' && pebblesData.length > 0 && (
                  <tr>
                    <td>Pebbles Portfolio</td>
                    <td>{formatNumber(pebblesData.reduce((sum, item) => sum + (parseFloat(item.top_bid) || 0), 0))}</td>
                    <td>N/A</td>
                    <td>{formatNumber(pebblesData.reduce((sum, item) => sum + (parseFloat(item.collection_floor_price) || 0), 0))}</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                )}
                <tr className="my-portfolio-total-row">
                  <td>Complete Collection</td>
                  <td>
                    {formatNumber(
                      (portfolioFilter !== 'pebbles' ? collectionValuation.bid : 0) +
                      (portfolioFilter !== 'memes' ? pebblesData.reduce((sum, item) => sum + (parseFloat(item.top_bid) || 0), 0) : 0)
                    )}
                  </td>
                  <td>
                    {portfolioFilter === 'pebbles' ? 'N/A' : formatNumber(
                      collectionValuation.withDepth +
                      (portfolioFilter !== 'memes' ? pebblesData.reduce((sum, item) => sum + (parseFloat(item.top_bid) || 0), 0) : 0)
                    )}
                  </td>
                  <td>
                    {formatNumber(
                      (portfolioFilter !== 'pebbles' ? collectionValuation.ask : 0) +
                      (portfolioFilter !== 'memes' ? pebblesData.reduce((sum, item) => sum + (parseFloat(item.collection_floor_price) || 0), 0) : 0)
                    )}
                  </td>
                  <td>{portfolioFilter === 'pebbles' ? 'N/A' : formatNumber(collectionValuation.lastSale)}</td>
                  <td>{portfolioFilter === 'pebbles' ? 'N/A' : formatNumber(completeCollectionCostToCompleteFloor)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Explanation Box */}
          <div className="my-portfolio-explanation-box">
            <p><strong>These valuations are calculated using:</strong></p>
            <ul>
              <li>Highest bid price per token</li>
              <li>Lowest ask price (floor price) per token</li>
              <li>Valuation considering market depth</li>
            </ul>
            <p><strong>Note:</strong> The new valuation method accounts for market depth by summing up available bids for the quantity of tokens you own.</p>
            {lastUpdated && (
              <>
                <p><strong>Prices Last Updated at:</strong> {formatDate(lastUpdated)}</p>
                <p><strong>** Price data updated approx every 2 minutes **</strong></p>
              </>
            )}
          </div>

          <br /><br />

          {/* Portfolio Details Table */}
          <h2 className="my-portfolio-sub-header">6529 Memes Portfolio</h2>

          {/* Link to MyPortfolioBidsComponent */}
          <div className="my-portfolio-link-container">
            <NavLink
              to={`/my-portfolio-bids?profile=${profile}`}
              target="_blank"
              rel="noopener noreferrer"
              className="my-portfolio-green-button"
            >
              View Bids Used in Valuation
            </NavLink>
          </div>

          {/* Filters and Search */}
          <div className="my-portfolio-filter-container">
            <div>
              <label htmlFor="szn-filter">SZN: </label>
              <select
                id="szn-filter"
                value={selectedSZN}
                onChange={(e) => {
                  setSelectedSZN(e.target.value);
                  setCurrentPage(0);
                }}
              >
                {uniqueSZNs.map((szn) => (
                  <option key={szn} value={szn}>{szn}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="ownership-filter">Ownership: </label>
              <select
                id="ownership-filter"
                value={ownershipFilter}
                onChange={(e) => {
                  setOwnershipFilter(e.target.value);
                  setCurrentPage(0);
                }}
              >
                <option value="all">All Tokens</option>
                <option value="owned">Owned Tokens</option>
                <option value="notowned">Tokens NOT Owned</option>
              </select>
            </div>
            <div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setShowSuggestions(true);
                  setCurrentPage(0);
                }}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                placeholder="Search cards..."
              />
              {showSuggestions && searchTerm.length >= 2 && (
                <ul className="my-portfolio-suggestions-list">
                  {portfolioData
                    .map(item => item.Name)
                    .filter((name, index, self) => 
                      name.toLowerCase().includes(searchTerm.toLowerCase()) && 
                      self.indexOf(name) === index
                    )
                    .slice(0, 5)
                    .map((suggestion, index) => (
                      <li
                        key={index}
                        onMouseDown={() => {
                          setSearchTerm(suggestion);
                          setShowSuggestions(false);
                          setCurrentPage(0);
                        }}
                      >
                        {suggestion}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>

          {/* Records count */}
          <div className="my-portfolio-records-count">
            <span><strong>Number of Records:</strong> {filteredData.length}</span>
          </div>

          {/* Table */}
          <table className="my-portfolio-details-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('ID')}>
                  <div className="my-portfolio-header-content">
                    Token No
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'ID' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
                <th>Card Name</th>
                <th onClick={() => handleSort('TypeSeason')}>
                  <div className="my-portfolio-header-content">
                    SZN
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'TypeSeason' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
                <th onClick={() => handleSort('HighestBid')}>
                  <div className="my-portfolio-header-content">
                    Highest Bid
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'HighestBid' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
                <th onClick={() => handleSort('FloorPrice')}>
                  <div className="my-portfolio-header-content">
                    Floor Price
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'FloorPrice' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
                <th onClick={() => handleSort('LastSalePrice')}>
                  <div className="my-portfolio-header-content">
                    Last Sale
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'LastSalePrice' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
                <th onClick={() => handleSort('PPTDH')}>
                  <div className="my-portfolio-header-content">
                    PPTDH
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'PPTDH' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
                <th onClick={() => handleSort('SeizedCount')}>
                  <div className="my-portfolio-header-content">
                    Seized Count
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'SeizedCount' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
                <th onClick={() => handleSort('BidValuation')}>
                  <div className="my-portfolio-header-content">
                    Bid Valuation
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'BidValuation' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
                <th onClick={() => handleSort('ValuationWithDepth')}>
                  <div className="my-portfolio-header-content">
                    Valuation (Bids Price With Depth)
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'ValuationWithDepth' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
                <th onClick={() => handleSort('AskValuation')}>
                  <div className="my-portfolio-header-content">
                    Ask Valuation
                    <ArrowUpDown
                      className={`my-portfolio-sort-icon ${sortConfig.key === 'AskValuation' ? (sortConfig.direction === 'ascending' ? 'my-portfolio-sorted-asc' : 'my-portfolio-sorted-desc') : ''}`}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr key={item.ID} className={ownershipFilter === 'all' && item.SeizedCount === 0 ? 'my-portfolio-highlight' : ''}>
                  <td>{item.ID}</td>
                  <td>
                    <NavLink
                      to={`/token/${item.ID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="my-portfolio-name-link"
                    >
                      {item.Name} <Info className="my-portfolio-inline-icon" />
                    </NavLink>
                  </td>
                  <td>{item.TypeSeason}</td>
                  <td>{formatNumber(item.HighestBid)}</td>
                  <td>{formatNumber(item.FloorPrice)}</td>
                  <td>{item.LastSalePrice !== null ? formatNumber(item.LastSalePrice) : 'N/A'}</td>
                  <td>{item.PPTDH !== null ? formatNumber(item.PPTDH) : 'N/A'}</td>
                  <td>{item.SeizedCount}</td>
                  <td>{formatNumber(item.BidValuation)}</td>
                  <td>{formatNumber(item.ValuationWithDepth)}</td>
                  <td>{formatNumber(item.AskValuation)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="my-portfolio-pagination">
            <ReactPaginate
              previousLabel={'← Previous'}
              nextLabel={'Next →'}
              breakLabel={'...'}
              pageCount={Math.ceil(filteredData.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'my-portfolio-pagination'}
              activeClassName={'active'}
            />
          </div>

          <br /><br /><br />

          {/* Pebbles Portfolio Table */}
          {paginatedPebblesData.length > 0 && (
            <>
              <h2 className="my-portfolio-sub-header">Pebbles Portfolio</h2>

              {/* Pebbles Records count */}
              <div className="my-portfolio-records-count">
                <span><strong>Number of Records:</strong> {filteredPebblesData.length}</span>
              </div>

              {/* Pebbles Table */}
              <table className="my-portfolio-details-table">
                <thead>
                  <tr>
                    <th>Token No</th>
                    <th>Name</th>
                    <th>Floor</th>
                    <th>Collection Floor</th>
                    <th>Top Bid</th>
                    <th>Last Sale</th>
                    <th>6529</th>
                    <th>OS</th>
                    <th>Blur</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedPebblesData.map((item) => {
                    const isSpecialLastSale = item.last_sale === '0.065290000000000000' && 
                                            new Date(item.last_sale_timestamp * 1000).toDateString() === new Date('2024-02-08').toDateString();
                    return (
                      <tr key={item.token_id}>
                        <td>{item.token_id}</td>
                        <td>
                          <NavLink
                            to={`/nextgen/pebbles/prices?token=${item.token_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="my-portfolio-name-link"
                          >
                            {item.name} <Info className="my-portfolio-inline-icon" />
                          </NavLink>
                        </td>
                        <td>{item.floor_price ? formatNumber(item.floor_price) : 'Not Listed'}</td>
                        <td>{item.collection_floor_price ? formatNumber(item.collection_floor_price) : 'Not Listed'}</td>
                        <td>
                          {item.top_bid ? (
                            <div className="my-portfolio-bid-container">
                              <span>{formatNumber(item.top_bid)}</span>
                              {item.top_bid_source_url && (
                                <a
                                  href={item.top_bid_source_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="my-portfolio-marketplace-link"
                                >
                                  {item.top_bid_source_icon && (
                                    <img
                                      src={item.top_bid_source_icon}
                                      alt="Marketplace"
                                      className="my-portfolio-marketplace-icon"
                                    />
                                  )}
                                </a>
                              )}
                            </div>
                          ) : (
                            'No Bid'
                          )}
                        </td>
                        <td className={isSpecialLastSale ? 'my-portfolio-special-text' : ''}>
                          {isSpecialLastSale ? (
                            'Fuck Off I\'ve Got Diamond Hands'
                          ) : item.last_sale ? (
                            formatNumber(item.last_sale)
                          ) : (
                            'Never Sold'
                          )}
                        </td>
                        <td>
                          <a
                            href={`https://6529.io/nextgen/token/${item.token_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={seizeIcon} alt="6529" className="my-portfolio-marketplace-icon" />
                          </a>
                        </td>
                        <td>
                          <a
                            href={`https://opensea.io/assets/ethereum/0x45882f9bc325e14fbb298a1df930c43a874b83ae/${item.token_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={openseaIcon} alt="OpenSea" className="my-portfolio-marketplace-icon" />
                          </a>
                        </td>
                        <td>
                          <a
                            href={`https://blur.io/eth/asset/0x45882f9bc325e14fbb298a1df930c43a874b83ae/${item.token_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={blurIcon} alt="Blur" className="my-portfolio-marketplace-icon" />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* Pebbles Pagination */}
              <div className="my-portfolio-pagination">
                <ReactPaginate
                  previousLabel={'← Previous'}
                  nextLabel={'Next →'}
                  breakLabel={'...'}
                  pageCount={Math.ceil(filteredPebblesData.length / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePebblesPageClick}
                  containerClassName={'my-portfolio-pagination'}
                  activeClassName={'active'}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MyPortfolioComponent;
